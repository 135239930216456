import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { useWallet } from 'context/WalletContext';
import { DIAMOND_LEVELS } from 'constants/bonus';
import { StandardButton } from 'components/Button';
import { DiamondIcon, NRGIcon } from 'components/ImageComponent';
import { PromotionCard, Container, IconProgress, BonusAmountText } from 'components/PromotionCard';
import { ActivatedButton } from 'components/StyledComponents';
import Modal from 'components/Modal';

const CustomFlex = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CustomTableRow = styled(TableRow)(({ theme, light }) => ({
  backgroundColor:
    light === 'true' ? theme.palette.background.tertiary : theme.palette.background.primary,

  '&:nth-child(even) td': {
    backgroundColor: theme.palette.background.tertiary,
  },
  '&:nth-child(odd) td': {
    backgroundColor: theme.palette.background.primary,
  },
}));

const CustomTableCell = styled(TableCell)(({ theme, bold }) => ({
  border: `1px solid ${theme.palette.border.decorative}`,
  fontWeight: bold === 'true' ? 700 : 500,

  '@media (max-width: 480px)': {
    fontSize: 11,
  },
}));

/********************  Main Component  ********************/
const DiamondBonusCard = ({ completed, level, percentage, nextLevel, total }) => {
  const { isAuthenticated } = useWallet();
  const [infoModal, setInfoModal] = useStateIfMounted(false);
  const [bonusAmount, setBonusAmount] = useStateIfMounted({
    value: DIAMOND_LEVELS.upToBonus,
    upTo: true,
    reserved: false,
  });

  const levelIndexes = Array.from({ length: 6 }, (_, i) => i.toString());

  const onClickOrHover = (index) => {
    setBonusAmount({ value: DIAMOND_LEVELS[index].percentage, upTo: false });
  };

  const onHoverOut = () => {
    setBonusAmount({ value: DIAMOND_LEVELS.upToBonus, upTo: true });
  };

  const hoverOnCompleted = () => {
    setBonusAmount({
      value: DIAMOND_LEVELS[level - 1].percentage,
      upTo: false,
      reserved: true,
    });
  };

  const onLoseFocus = () => {
    setBonusAmount({
      value: DIAMOND_LEVELS.upToBonus,
      upTo: true,
      reserved: false,
    });
  };

  const toggle = () => setInfoModal(!infoModal);

  return (
    <>
      <Modal onDismiss={toggle} isOpen={infoModal} title="NRG Diamond Hands Bonus" width={500}>
        <Box padding={3}>
          <Typography marginBottom={3}>
            The table below lists the bonuses for holding NRG across all your linked accounts.
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <CustomTableRow light="true">
                  <CustomTableCell align="right" bold="true">
                    NRG Holding
                  </CustomTableCell>
                  <CustomTableCell align="center" bold="true">
                    Additional Bonus
                  </CustomTableCell>
                </CustomTableRow>
              </TableHead>
              <TableBody>
                {levelIndexes.map((index) => (
                  <CustomTableRow key={index}>
                    <CustomTableCell align="right">
                      {`>=`} {DIAMOND_LEVELS[index].limit.toLocaleString()}
                      {DIAMOND_LEVELS[Number(index) + 1]
                        ? ` and < ${DIAMOND_LEVELS[Number(index) + 1].limit.toLocaleString()}`
                        : ''}
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {DIAMOND_LEVELS[index].percentage}%
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <PromotionCard
        Icon={DiamondIcon}
        title="NRG Diamond Hands Bonus"
        tooltip="Purchase and hold NRG until the GMI token generation event to receive multiplier bonus"
        handleTooltip={toggle}
        completed={completed}
      >
        <Container>
          <Box>
            <CustomFlex>
              <IconProgress
                diamond
                currentLevel={level}
                maxLevel={DIAMOND_LEVELS.maxLevel}
                completed={completed}
                onClickOrHover={onClickOrHover}
                onHoverOut={onHoverOut}
                hoverOnCompleted={hoverOnCompleted}
                onLoseFocus={onLoseFocus}
              />
              <Box>
                <Typography fontSize={12} fontWeight={400}>
                  {!completed ? (
                    <>
                      <NRGIcon width={12} height={12} /> {parseInt(total).toLocaleString()}/
                      {nextLevel.toLocaleString()} to Level {level + 1}
                    </>
                  ) : (
                    'Max level reached'
                  )}
                </Typography>
              </Box>
            </CustomFlex>
            <BonusAmountText upTo {...bonusAmount} />
          </Box>
          {!isAuthenticated && <StandardButton fullWidth>Connect to Metamask!</StandardButton>}
          {isAuthenticated && level > 0 && (
            <ActivatedButton>{percentage.toFixed(0)}% BONUS Activated</ActivatedButton>
          )}
        </Container>
      </PromotionCard>
    </>
  );
};

export default DiamondBonusCard;
