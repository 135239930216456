import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Box, Typography, styled } from '@mui/material';

import { SPREAD_WORD_LEVELS } from 'constants/bonus';
import { useWallet } from 'context/WalletContext';

import { StandardButton, ConnectButton } from 'components/Button';
import {
  PromotionCard,
  BonusAmountText,
  CongratulationsModal,
  IconProgress,
  Container,
} from 'components/PromotionCard';
import { SpreadWordIcon } from 'components/ImageComponent';
import SpreadWordTooltipModal from 'components/PromotionCard/modals/SpreadWordTooltipModal';

/********************  Styled Components  ********************/
const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

/********************  Main Component  ********************/
const SpreadTheWordCard = ({ completed, level, referralCount }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useWallet();

  const [modalIsOpen, setModalOpen] = useStateIfMounted(false);
  const [currentLevel, setCurrentLevel] = useStateIfMounted(0);
  const [tooltipOpen, setTooltipOpen] = useStateIfMounted(false);

  const [bonusAmount, setBonusAmount] = useStateIfMounted({
    value: SPREAD_WORD_LEVELS.upToBonus,
    upTo: true,
    reserved: false,
  });

  useEffect(() => {
    level && setCurrentLevel(level);
  }, [level]);

  const onClickOrHover = (index) => {
    setBonusAmount({ value: SPREAD_WORD_LEVELS[index + 1].percentage, upTo: false });
  };

  const onHoverOut = () => {
    setCurrentLevel(level);
    setBonusAmount({ value: SPREAD_WORD_LEVELS.upToBonus, upTo: true });
  };

  const hoverOnCompleted = () => {
    setCurrentLevel(level);
    setBonusAmount({
      value: SPREAD_WORD_LEVELS[level].percentage,
      upTo: false,
      reserved: true,
    });
  };

  const onLoseFocus = () => {
    setCurrentLevel(level);
    setBonusAmount({
      value: SPREAD_WORD_LEVELS.upToBonus,
      upTo: true,
      reserved: false,
    });
  };

  // Avoid ternary to avoid auto click on initial render
  const handleButtonClick = () => {
    if (level > 0 && level < SPREAD_WORD_LEVELS.maxLevel) {
      setModalOpen(true);
    } else {
      navigate('/referral'); // Replace with the path you want to redirect to
    }
  };

  return (
    <>
      <PromotionCard
        Icon={SpreadWordIcon}
        title="Spread the Word Bonus"
        tooltip={true}
        handleTooltip={() => setTooltipOpen(true)}
        completed={completed}
      >
        <Container>
          <Box>
            <FlexBox>
              <IconProgress
                money
                currentLevel={currentLevel}
                maxLevel={SPREAD_WORD_LEVELS.maxLevel}
                completed={completed}
                onClickOrHover={onClickOrHover}
                onHoverOut={onHoverOut}
                hoverOnCompleted={hoverOnCompleted}
                onLoseFocus={onLoseFocus}
              />
              <Typography fontSize={12} fontWeight={500}>
                {level === SPREAD_WORD_LEVELS.maxLevel
                  ? 'Max Level'
                  : `${referralCount}/${
                      SPREAD_WORD_LEVELS[level + 1].referrals
                    } friends for Level ${level + 1}`}
              </Typography>
            </FlexBox>
            <Box marginTop={1}>
              <BonusAmountText {...bonusAmount} />
            </Box>
          </Box>
          {!isAuthenticated ? (
            <ConnectButton height={40} />
          ) : (
            <StandardButton fullWidth onClick={handleButtonClick}>
              Invite Friends!!
            </StandardButton>
          )}
        </Container>
      </PromotionCard>

      <CongratulationsModal
        isOpen={modalIsOpen}
        closeModal={() => setModalOpen(false)}
        title={`Spread the word Level ${level} bonus successfully activated!`}
        subTitle="Invite more friends to activate the bonus up to 50%"
        bonuses={[{ icon: SpreadWordIcon, name: 'Spread the word bonus', amount: level * 10 }]}
      />

      <SpreadWordTooltipModal isOpen={tooltipOpen} onClose={() => setTooltipOpen(false)} />
    </>
  );
};

export default SpreadTheWordCard;
