import { ApiClient } from '../ApiClient';

export class EmailApi {
  /**
   * Constructs a new EmailApi.
   * @alias module:api/EmailApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Reserve email
   *
   * @param {{name: string, address: string, email: string}} body
   */
  async reserveEmail(body) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/email/reserveEmail', 'post', {}, {}, null, headers, body);
  }

  /**
   *  Verify email
   *
   * @param {string} token
   */
  async verifyEmail(token) {
    return await this.apiClient.callApi('/email/verifyEmail', 'get', token);
  }

  /**
   *  Resend email
   *
   * @param {{email: string}} body
   */
  async resendEmail(body) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/email/resendEmail', 'post', {}, {}, null, headers, body);
  }
}
