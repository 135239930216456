import { Box, styled } from '@mui/material';

import Level1SVG from 'assets/season2/levels/level-1.svg';
import Level2SVG from 'assets/season2/levels/level-2.svg';
import Level3SVG from 'assets/season2/levels/level-3.svg';
import Level4SVG from 'assets/season2/levels/level-4.svg';
import Level5SVG from 'assets/season2/levels/level-5.svg';
import Level6SVG from 'assets/season2/levels/level-6.svg';
import Level7SVG from 'assets/season2/levels/level-7.svg';
import Level8SVG from 'assets/season2/levels/level-8.svg';
import Level9SVG from 'assets/season2/levels/level-9.svg';
import Level10SVG from 'assets/season2/levels/level-10.svg';
import LevelMaxSVG from 'assets/season2/levels/level-max.svg';

const LevelBadge = styled(Box)(({ level, size, theme }) => {
  let levelImage;
  if (level < 10) {
    levelImage = Level1SVG;
  } else if (level < 20) {
    levelImage = Level2SVG;
  } else if (level < 30) {
    levelImage = Level3SVG;
  } else if (level < 40) {
    levelImage = Level4SVG;
  } else if (level < 50) {
    levelImage = Level5SVG;
  } else if (level < 60) {
    levelImage = Level6SVG;
  } else if (level < 70) {
    levelImage = Level7SVG;
  } else if (level < 80) {
    levelImage = Level8SVG;
  } else if (level < 90) {
    levelImage = Level9SVG;
  } else if (level < 100) {
    levelImage = Level10SVG;
  } else if (level === 100) {
    levelImage = LevelMaxSVG;
  }

  return {
    width: size === 'small' ? 30 : 58,
    height: size === 'small' ? 30 : 58,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${levelImage})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: size === 'small' ? 14 : 24,
    fontWeight: 600,
    '& span': {
      marginTop: level === 100 ? (size === 'small' ? theme.spacing(-1) : theme.spacing(-2)) : 0,
    },
  };
});

const LevelBox = ({ level = 0, size = 'small' }) => {
  return (
    <LevelBadge size={size} level={level}>
      <span>{level}</span>
    </LevelBadge>
  );
};

export default LevelBox;
