import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide11 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>More Seasons!</ModalHeading>
        <ModalText>
          This is just the beginning! Look forward to more seasons with new airdrop programs and
          additional bonuses.
        </ModalText>
      </Box>
      <ImageBox imagewidth={376}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide11;
