import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide9 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>Referral Program</ModalHeading>
        <ModalText>Boost your points by referring others to the GMI Airdrop!</ModalText>
        <br />
        <UnorderedList>
          <li>
            <ModalText>
              <strong>Earn Bonus Points:</strong> Get a 30% bonus on your base rewards for each
              referral.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Multi-Tier Rewards:</strong> Earn 30% from Level 1 referrals, 20% from Level
              2, and 10% from Level 3 referrals.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Spread the Word:</strong> The more people you refer, the more points you earn.
            </ModalText>
          </li>
        </UnorderedList>
        <br />
        <ModalText>
          Start referring and maximize your rewards in the GMI Airdrop Season 2!
        </ModalText>
      </Box>
      <ImageBox imagewidth={313}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide9;
