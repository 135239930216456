import { Box, styled, Typography } from '@mui/material';
import Modal from 'components/Modal';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3, 3, 3),
  width: 556,
}));

const CustomText = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'left',
}));

const CustomList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyleType: 'disc',
  paddingInlineStart: theme.spacing(2.5),
}));

const CustomListItem = styled('li')(() => ({
  padding: 0,
  margin: 0,
}));

const ApedInTooltipModal = ({ isOpen, onClose }) => {
  return (
    <Modal title="How to Ape in?" isOpen={isOpen} onDismiss={onClose} width={589}>
      <Container>
        <CustomText>
          The Ape in bonus is divided into 7 rounds, with the BONUSer decreasing after each round.
          This means that the earlier you complete all actions, the greater bonus you&apos;ll
          activate!
        </CustomText>
        <Box>
          <CustomText>To qualify for the Ape in bonus, you need to:</CustomText>
          <CustomList>
            <CustomListItem>Follow @GonnaMakeItNFTs on X</CustomListItem>
            <CustomListItem>Follow @Energi on X</CustomListItem>
            <CustomListItem>Follow @TommyWorldPower on X</CustomListItem>
            <CustomListItem>Join Energi Discord Community</CustomListItem>
            <CustomListItem>Join GonnaMakeIt NFTs Telegram channel</CustomListItem>
          </CustomList>
        </Box>
        <CustomText>
          For each round we secured limited amount of spots:
          <br />
          &nbsp;1. 50% bonus - 1,000 spots
          <br />
          &nbsp;2. 40% bonus - 3,000 spots
          <br />
          &nbsp;3. 35% bonus - 6,000 spots
          <br />
          &nbsp;4. 30% bonus - 10,000 spots
          <br />
          &nbsp;5. 25% bonus - 20,000 spots
          <br />
          &nbsp;6. 22% bonus - 69,000 spots
          <br />
          &nbsp;7. 20% bonus - 420,000 spots
        </CustomText>
        <CustomText>
          Additionally, it&apos;s important to note that the bonuses from each round do not stack
          up. The maximum bonus achievable is 50%, which is offered in Round 1. Make sure to
          activate your bonus early to secure the highest possible boost of 50%.
        </CustomText>
      </Container>
    </Modal>
  );
};

export default ApedInTooltipModal;
