import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { StandardButton } from 'components/Button';
import { ApedInModal } from 'components/PromotionCard/modals';

const ReserveBonusButton = ({ apedIn, disabled, refetchData, percentage }) => {
  const [bonusModalOpen, setBonusModalOpen] = useStateIfMounted(false);

  return (
    <>
      <StandardButton
        fullWidth
        height={40}
        onClick={() => setBonusModalOpen(true)}
        disabled={disabled}
      >
        Activate Bonus
      </StandardButton>

      {apedIn && (
        <ApedInModal
          isOpen={bonusModalOpen}
          onDismiss={() => setBonusModalOpen(false)}
          refetchData={refetchData}
          percentage={percentage}
        />
      )}
    </>
  );
};

export default ReserveBonusButton;
