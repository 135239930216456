import React from 'react';
import { Box, Typography } from '@mui/material';

import { REFERRAL_PROGRAM_WEEKLY_BONUS, hasWeeklyRPStarted } from 'constants/bonus';
import { REFERRAL_PROGRAM_URL } from 'constants';
import userService from 'services/user';
import { socialMediaShare } from 'utils/socialMediaShare';
import { useWallet } from 'context/WalletContext';
import useNotification from 'hooks/useNotification';

import { BirdIcon } from 'components/ImageComponent';
import { PromotionCard, FlexBox, Container, BonusAmountText } from 'components/PromotionCard';
import { StandardButton, ConnectButton } from 'components/Button';
import { ActivatedButton } from 'components/StyledComponents';

/********************  Main Component  ********************/
const TweetBonusCard = ({ week, completed, refetchData }) => {
  const { isAuthenticated, twitterUser, address } = useWallet();
  const showNotification = useNotification();
  const weekStarted = hasWeeklyRPStarted(week);

  const claim = async () => {
    const user_rp_link = `${REFERRAL_PROGRAM_URL}/${twitterUser ?? address}`;
    socialMediaShare(user_rp_link, `Weekly RP Booster week${week}`);
    const response = await userService.claimReferralProgramWeekly(week);
    if (response.success) {
      refetchData();
    } else {
      showNotification({
        type: 'error',
        message: response.message,
      });
    }
  };

  return (
    <PromotionCard
      Icon={BirdIcon}
      title={`Week ${week} Tweet Bonus`}
      tooltip="Tweet out your referral link to gain extra boost"
      completed={completed}
      started={weekStarted}
    >
      <Container>
        <FlexBox flex={1}>
          <Box>
            <Typography fontSize={12} fontWeight={500} marginBottom={1.5}>
              Complete 1 Power Post to claim Bonus
            </Typography>
            {!completed && (
              <Box>
                <BonusAmountText value={REFERRAL_PROGRAM_WEEKLY_BONUS} />
              </Box>
            )}
          </Box>
        </FlexBox>
        {!isAuthenticated ? (
          <ConnectButton height={40}>
            {weekStarted ? null : `Activates in week ${week}`}
          </ConnectButton>
        ) : completed ? (
          <ActivatedButton>{REFERRAL_PROGRAM_WEEKLY_BONUS}% BONUS Activated</ActivatedButton>
        ) : (
          <StandardButton height={40} onClick={claim}>
            {weekStarted ? 'Claim Now!' : `Activates in week ${week}`}
          </StandardButton>
        )}
      </Container>
    </PromotionCard>
  );
};

export default TweetBonusCard;
