export const LEVELS = [
  { level: 1, points: 0, boost: 0 },
  { level: 2, points: 100, boost: 1 },
  { level: 3, points: 225, boost: 2 },
  { level: 4, points: 400, boost: 3 },
  { level: 5, points: 625, boost: 4 },
  { level: 6, points: 900, boost: 5 },
  { level: 7, points: 1225, boost: 6 },
  { level: 8, points: 1600, boost: 7 },
  { level: 9, points: 2025, boost: 8 },
  { level: 10, points: 2500, boost: 10 },
  { level: 11, points: 3025, boost: 12 },
  { level: 12, points: 3600, boost: 14 },
  { level: 13, points: 4225, boost: 16 },
  { level: 14, points: 4900, boost: 18 },
  { level: 15, points: 5625, boost: 20 },
  { level: 16, points: 6400, boost: 22 },
  { level: 17, points: 7225, boost: 24 },
  { level: 18, points: 8100, boost: 26 },
  { level: 19, points: 9025, boost: 28 },
  { level: 20, points: 10000, boost: 31 },
  { level: 21, points: 11025, boost: 34 },
  { level: 22, points: 12100, boost: 37 },
  { level: 23, points: 13225, boost: 40 },
  { level: 24, points: 14400, boost: 43 },
  { level: 25, points: 15625, boost: 46 },
  { level: 26, points: 16900, boost: 49 },
  { level: 27, points: 18225, boost: 52 },
  { level: 28, points: 19600, boost: 55 },
  { level: 29, points: 21025, boost: 58 },
  { level: 30, points: 22500, boost: 62 },
  { level: 31, points: 24025, boost: 66 },
  { level: 32, points: 25600, boost: 70 },
  { level: 33, points: 27225, boost: 74 },
  { level: 34, points: 28900, boost: 78 },
  { level: 35, points: 30625, boost: 82 },
  { level: 36, points: 32400, boost: 86 },
  { level: 37, points: 34225, boost: 90 },
  { level: 38, points: 36100, boost: 94 },
  { level: 39, points: 38025, boost: 98 },
  { level: 40, points: 40000, boost: 103 },
  { level: 41, points: 42025, boost: 108 },
  { level: 42, points: 44100, boost: 113 },
  { level: 43, points: 46225, boost: 118 },
  { level: 44, points: 48400, boost: 123 },
  { level: 45, points: 50625, boost: 128 },
  { level: 46, points: 52900, boost: 133 },
  { level: 47, points: 55225, boost: 138 },
  { level: 48, points: 57600, boost: 143 },
  { level: 49, points: 60025, boost: 148 },
  { level: 50, points: 62500, boost: 154 },
  { level: 51, points: 65025, boost: 160 },
  { level: 52, points: 67600, boost: 166 },
  { level: 53, points: 70225, boost: 172 },
  { level: 54, points: 72900, boost: 178 },
  { level: 55, points: 75625, boost: 184 },
  { level: 56, points: 78400, boost: 190 },
  { level: 57, points: 81225, boost: 196 },
  { level: 58, points: 84100, boost: 202 },
  { level: 59, points: 87025, boost: 208 },
  { level: 60, points: 90000, boost: 215 },
  { level: 61, points: 93025, boost: 222 },
  { level: 62, points: 96100, boost: 229 },
  { level: 63, points: 99225, boost: 236 },
  { level: 64, points: 102400, boost: 243 },
  { level: 65, points: 105625, boost: 250 },
  { level: 66, points: 108900, boost: 257 },
  { level: 67, points: 112225, boost: 264 },
  { level: 68, points: 115600, boost: 271 },
  { level: 69, points: 119025, boost: 278 },
  { level: 70, points: 122500, boost: 286 },
  { level: 71, points: 126025, boost: 294 },
  { level: 72, points: 129600, boost: 302 },
  { level: 73, points: 133225, boost: 310 },
  { level: 74, points: 136900, boost: 318 },
  { level: 75, points: 140625, boost: 326 },
  { level: 76, points: 144400, boost: 334 },
  { level: 77, points: 148225, boost: 342 },
  { level: 78, points: 152100, boost: 350 },
  { level: 79, points: 156025, boost: 358 },
  { level: 80, points: 160000, boost: 367 },
  { level: 81, points: 164025, boost: 376 },
  { level: 82, points: 168100, boost: 385 },
  { level: 83, points: 172225, boost: 394 },
  { level: 84, points: 176400, boost: 403 },
  { level: 85, points: 180625, boost: 412 },
  { level: 86, points: 184900, boost: 421 },
  { level: 87, points: 189225, boost: 430 },
  { level: 88, points: 193600, boost: 439 },
  { level: 89, points: 198025, boost: 448 },
  { level: 90, points: 202500, boost: 458 },
  { level: 91, points: 207025, boost: 468 },
  { level: 92, points: 211600, boost: 478 },
  { level: 93, points: 216225, boost: 488 },
  { level: 94, points: 220900, boost: 498 },
  { level: 95, points: 225625, boost: 508 },
  { level: 96, points: 230400, boost: 518 },
  { level: 97, points: 235225, boost: 528 },
  { level: 98, points: 240100, boost: 538 },
  { level: 99, points: 245025, boost: 548 },
  { level: 100, points: 250000, boost: 558 },
];

export const ZEALY_BONUS = 30;

export const BONUS_TYPES = {
  all: (item) => !(item.expired && !item.completed),
  completed: (item) => item.completed,
  incomplete: (item) => !item.expired && !item.completed,
};

export const getLevelByXP = (xp) => {
  let currentLevel = LEVELS[0];
  for (let i = 0; i < LEVELS.length; i++) {
    if (xp >= LEVELS[i].points) {
      currentLevel = LEVELS[i];
    } else {
      break;
    }
  }
  return currentLevel;
};

export const getNextLevelXP = (currentLevel) => {
  if (currentLevel < 0 || currentLevel >= LEVELS.length) {
    return 0;
  }

  return LEVELS[currentLevel].points;
};

export const getBoostbyLevel = (level) => {
  const index = level - 1;
  if (index < 0 || index >= LEVELS.length) {
    return 0;
  }
  return LEVELS[index].boost;
};

export const getLevel = (level) => {
  const index = level - 1;
  if (index < 0 || index >= LEVELS.length) {
    return 0;
  }
  return LEVELS[index];
};
