import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
  listStyleType: 'disc',
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide8 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>Trader Airdrop</ModalHeading>
        <ModalText>Earn points actively trading NFTs on the GMI Marketplace:</ModalText>
        <br />
        <UnorderedList>
          <li>
            <ModalText>
              <strong>Trade Volume:</strong> Earn 1 point per 1 NRG in trading volume per day.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Bidding/Listing Points:</strong> Earn additional points by bidding and listing
              NFTs.
            </ModalText>
            <UnorderedList>
              <li>Closer to the floor price earns more points.</li>
              <li>Longer active bids/listing earn more points.</li>
            </UnorderedList>
          </li>
        </UnorderedList>
        <br />
        <ModalText>
          Boost your rewards by trading and engaging in the GMI NFT Marketplace!
        </ModalText>
      </Box>
      <ImageBox imagewidth={413}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide8;
