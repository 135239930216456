import { forwardRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { TextField, Typography } from '@mui/material';

const getInputBackgroundColor = (theme) => {
  return theme.palette.mode === 'dark'
    ? theme.palette.background.tertiary
    : theme.palette.background.default;
};

/************************* Styled Components  **************************/
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Label = styled('label')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.main,
  marginBottom: theme.spacing(1),
}));

const RequiredText = styled('span')(({ theme }) => ({
  color: theme.palette.text.error,
}));

const CustomizedTextField = styled(TextField)(({ theme, height, partialdisabled, success }) => ({
  '& input': {
    borderRadius: 8,
    padding: 0,
    paddingLeft: 16,
    paddingRight: 16,
    height: height ?? '',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    WebkitTextFillColor: success
      ? theme.palette.support.success
      : partialdisabled
      ? theme.palette.text.secondary
      : theme.palette.text.tertiary,

    backgroundColor: getInputBackgroundColor(theme),

    '&::placeholder': {
      color: theme.palette.text.secondary,
    },
  },

  '& fieldset': {
    borderRadius: 8,
    borderColor: theme.palette.border.decorative,
  },

  '& input:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0 30px ${getInputBackgroundColor(theme)} inset !important`,
    borderRadius: 8,
    WebkitTextFillColor: success
      ? theme.palette.support.success
      : partialdisabled
      ? theme.palette.text.secondary
      : theme.palette.text.tertiary,
  },

  '& .Mui-disabled': {
    '& input': {
      WebkitTextFillColor: success ? theme.palette.support.success : theme.palette.text.secondary,
      opacity: 0.54,
      backgroundColor: success ? getInputBackgroundColor(theme) : theme.palette.background.tertiary,
    },

    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: success ? theme.palette.support.success : theme.palette.background.tertiary,
    },
  },

  '& .Mui-error': {
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.danger.color,
    },
  },

  '& .MuiFormHelperText-root.Mui-error': {
    color: theme.palette.text.error,
    margin: 0,
    marginTop: 8,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
}));

/************************* Main Component  **************************/
// eslint-disable-next-line react/display-name
const TextInput = forwardRef(
  (
    {
      title,
      placeholder,
      name,
      children,
      error = false,
      helperText = '',
      disabled = false,
      required = false,
      rows = 0,
      value = '',
      onChange,
      width,
      height,
      success,
      partialDisabled,
    },
    ref,
  ) => {
    const theme = useTheme();

    return (
      <Container sx={{ width }}>
        {title && (
          <Label htmlFor={`field-${name}`}>
            {title}
            {required && <RequiredText> *</RequiredText>}
          </Label>
        )}
        {children ? (
          <Typography
            fontSize={12}
            color={theme.palette.text.medium}
            paddingBottom="0.5rem"
            display="inline"
          >
            {children}
          </Typography>
        ) : null}
        <CustomizedTextField
          name={name}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          id={`field-${name}`}
          multiline={!!rows}
          rows={rows}
          disabled={disabled}
          value={value}
          onChange={(ev) => onChange(ev) || {}}
          height={height}
          success={success}
          partialdisabled={partialDisabled}
          inputRef={ref}
        />
      </Container>
    );
  },
);

export default TextInput;
