import { useContext, useMemo } from 'react';
import { Box, Button, LinearProgress, Stack, Typography, styled } from '@mui/material';
import { useStateIfMounted } from 'use-state-if-mounted';

import { AppContext } from 'context/AppContext';

import Modal from 'components/Modal';
import Welcome from './Welcome';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';
import Slide6 from './Slide6';
import Slide7 from './Slide7';
import Slide8 from './Slide8';
import Slide9 from './Slide9';
import Slide10 from './Slide10';
import Slide11 from './Slide11';
import Slide12 from './Slide12';
import YouGetMeme from 'assets/season2/you-get.png';
import BernieAskingMeme from 'assets/season2/bernie-asking.png';
import PeoplePowerMeme from 'assets/season2/people-power.png';
import DrakeMeme from 'assets/season2/drake.png';
import ByAllMeansMeme from 'assets/season2/by-all-means.png';
import SpongeBobMeme from 'assets/season2/sponge-bob.png';
import WolfWallStreetMeme from 'assets/season2/wolf-wall-street.png';
import WillFerrellMeme from 'assets/season2/will-ferrell.png';
import VinceMeme from 'assets/season2/vince.png';
import AnyMoreOfMeme from 'assets/season2/any-more-of.png';
import WhatIfMeme from 'assets/season2/what-if.png';

const PrevButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  borderRadius: theme.spacing(1),
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.border.decorative}`,
  height: 48,
}));

const NextButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  borderRadius: theme.spacing(1),
  height: 48,
}));

export const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(3),

  '@media (max-width: 905px)': {
    flexDirection: 'column',
  },
}));

export const ImageBox = styled(Box)(({ theme, imagewidth }) => ({
  '& img': {
    borderRadius: theme.spacing(1),
    width: imagewidth,
  },

  '@media (max-width: 905px)': {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '65%',
    },
  },
}));

export const ModalHeading = styled(Typography)(({ theme }) => ({
  fontSize: 42,
  fontWeight: 500,
  marginBottom: theme.spacing(2),

  '@media (max-width: 905px)': {
    fontSize: 24,
  },
}));

export const ModalText = styled(Typography)(({}) => ({
  fontSize: 16,
  fontWeight: 400,

  '@media (max-width: 905px)': {
    fontSize: 14,
  },
}));

export const List = styled('ol')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(1.5),
  listStyleType: 'none',
}));

const HeadingContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),

  '@media (max-width: 905px)': {
    marginBottom: theme.spacing(2),
  },
}));

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 9,
  borderRadius: 200,
}));

const Onboarding = () => {
  const [currentModal, setCurrentModal] = useStateIfMounted(0);
  const { setOnboardingModal, state } = useContext(AppContext);

  const open = state.onboardingModal;

  const next = () => {
    setCurrentModal(currentModal + 1);
  };

  const prev = () => {
    setCurrentModal(currentModal - 1);
  };

  const dismiss = () => {
    localStorage.setItem('onboarded', true);
    setOnboardingModal(false);
  };

  const process = useMemo(
    () => [
      <Welcome key={1} dismiss={dismiss} next={next} />,
      <Slide2 image={YouGetMeme} key={2} />,
      <Slide3 image={BernieAskingMeme} key={3} />,
      <Slide4 image={PeoplePowerMeme} key={4} />,
      <Slide5 image={DrakeMeme} key={5} />,
      <Slide6 image={ByAllMeansMeme} key={6} />,
      <Slide7 image={SpongeBobMeme} key={7} />,
      <Slide8 image={WolfWallStreetMeme} key={8} />,
      <Slide9 image={WillFerrellMeme} key={9} />,
      <Slide10 image={VinceMeme} key={10} />,
      <Slide11 image={AnyMoreOfMeme} key={11} />,
      <Slide12 image={WhatIfMeme} key={12} />,
    ],
    [],
  );

  const totalModals = process.length;
  const isLast = currentModal === process.length - 1;

  return (
    <Modal isOpen={open} onDismiss={dismiss} width={900}>
      <Box padding={3} width={'100%'}>
        <HeadingContainer>
          <Typography textAlign="right" marginBottom={0.5}>{`${
            currentModal + 1
          }/${totalModals}`}</Typography>
          <BorderLinearProgress
            value={(100 / totalModals) * (currentModal + 1)}
            variant="determinate"
          />
        </HeadingContainer>
        <Box marginTop={2}>{process[currentModal]}</Box>
        {currentModal > 0 && (
          <Box marginTop={3} display="flex" justifyContent="space-between">
            <PrevButton variant="contained" onClick={prev}>
              Previous
            </PrevButton>
            <NextButton variant="contained" onClick={isLast ? dismiss : next}>
              {isLast ? 'Start Playing' : 'Next'}
            </NextButton>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default Onboarding;
