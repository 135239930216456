import { useContext } from 'react';
import { styled, Box, useMediaQuery, useTheme } from '@mui/material';

import { DashboardContext } from 'context/DashboardContext';

import { PostsIcon, ViewsIcon } from 'components/ImageComponent';
import { Text } from 'components/Text';

const Container = styled(Box)(({ theme }) => ({
  height: 76,
  display: 'flex',
  margin: theme.spacing(3, 0),
  padding: theme.spacing(0, 2),
  gap: theme.spacing(2),
  justifyContent: 'center',
  '& > div': { flex: 1, maxWidth: 180 },
}));

const EngagementBox = styled(Box)(({ theme, backgroundColor, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${color}`,
  borderRadius: theme.spacing(1),
  backgroundColor: backgroundColor,
}));

const Engagement = ({ color, backgroundColor, title, value, icon }) => {
  const above600 = useMediaQuery('(min-width: 600px)');

  return (
    <EngagementBox backgroundColor={backgroundColor} color={color}>
      <Text color={color} fs={16} fw={600}>
        {icon}
        {above600 && title}
      </Text>
      <Text fs={20} fw={600}>
        {value}
      </Text>
    </EngagementBox>
  );
};

const Engagements = () => {
  const theme = useTheme();
  const { state } = useContext(DashboardContext);
  const { xViewCount, xNumPost } = state.data || {};

  return (
    <Container>
      <Engagement
        {...theme.palette.Engagements.posts}
        title="Posts"
        value={xNumPost ?? 0}
        icon={<PostsIcon sx={{ marginRight: 1, marginTop: -0.5 }} />}
      />
      <Engagement
        {...theme.palette.Engagements.views}
        title="Views"
        value={xViewCount ?? 0}
        icon={<ViewsIcon sx={{ marginRight: 1, marginTop: -0.5 }} />}
      />
    </Container>
  );
};

export default Engagements;
