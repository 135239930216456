import { createTheme } from '@mui/material/styles';

const typography = {
  fontFamily: [
    'Inter',
    'Open Sans',
    'Roboto',
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji',
  ].join(','),
  button: {
    textTransform: 'none',
  },
};

const breakpoints = {
  values: {
    xs: 360,
    sm: 600,
    md: 905,
    lg: 1280,
    xl: 1440,
    desktop: 1600,
  },
};

export const darkTheme = createTheme({
  typography,
  breakpoints,
  spacing: 8,
  palette: {
    mode: 'dark',
    timer: '#141619',
    text: {
      main: '#FFFFFF', // primary, body, header, hover
      secondary: '#81909E', // input labels
      tertiary: '#C4CBD1', // help text
      placeholder: '#6C7C8C',
      interactive: '#FFFFFF',
      error: '#F86D7A',
      light: '#8B939B',
      white: '#FFFFFF',
      medium: '#707083',
      subtitle: '#707A83',
      menu: '#434D55',
      red: '#EB5757',
      crimson: '#FF4258',
      dark: '#212121',
      grey: '#707A83',
      title: '#04111D',
      lightGreen: '#B3F8D6',
      brightRed: '#E70000',
      active: '#00E676',
      yellow: '#FEAC1C',
      darkGreen: '#02CC6A',
    },
    button: {
      // bg color
      primary: '#00AB58',
      secondary: '#FFFFFF',
      tertiary: '#00E676',
      active: '#00E676',
      rgba: 'rgba(0, 230, 118, 0.3)',
    },
    danger: {
      color: '#E0002E', // button bg
      variant: '#F86D7A', // text, icon, border
    },
    background: {
      default: '#141619',
      secondary: '#141619',
      light: '#141619',
      tertiary: '#2E333A',
      overlay: 'rgba(0, 0, 0, 0.8)',
      lighter: '#F3FBFE',
      midLight: '#D9D9D9',
      linear:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #141619',
      hover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #141619',
      hoverChrome: '#3B4149',
      offer: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #2E333A',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #2E333A',
      },
      filterchip: {
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #002211',
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #002211',
      },
      dropdown: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #2E333A',
      },
      filter: '#1A2024',
      button: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #00AB58',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #00AB58',
      },
      apply: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #141619',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #141619',
      },
      clear: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #002211',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #002211',
      },
    },
    border: {
      main: '#212121',
      primary: '#002211',
      medium: '#6C7C8C',
      high: '#81909E',
      separator: '#2E333A',
      decorative: '#525F6B',
      light: '#CCCCCC',
      shadow: '#444',
      white: '#FFF',
    },
    link: {
      primary: '#00E676',
      secondary: '#0EFF8A',
      inverse: '#A2ADB8',
    },
    icon: {
      primary: '#FFFFFF',
      secondary: '#A2ADB8',
      tertiary: '#81909E',
      interactive: '#FFFFFF',
      special: '#FF4258',
      clock: '#DADADA',
      dark: '#525F6B',
    },
    disabled: {
      color: '#141619', // fields, bg, border
    },
    support: {
      error: '#FF4258',
      success: '#00E676',
      warning: '#F6C000',
      warning2: '#F2994A',
      information: '#65A8EB',
      shadow: '#00B55C',
    },
    footer: {
      background: '#141619',
      text: '#A2ADB8',
      input: '#E5E8EB',
    },
    shadow: '#6C7C8C',
    primary: {
      main: '#00AB58',
      darker: '#00AB58',
      lighter: '#45FF9A',
    },
    medium: {
      main: '#707083',
    },
    Engagements: {
      posts: {
        color: '#4496D1',
        backgroundColor: 'rgba(19, 50, 73, 0.64)',
      },
      views: {
        color: '#7879F1',
        backgroundColor: '#241B2F',
      },
    },
    Leaderboards: {
      border: '#39FF14',
      primary: '#20E600',
      secondary: '#0DCEF9',
      background: '#123426',
    },
    powerPosts: {
      active: '#2E333A',
      completed: '#021',
      inactive: 'rgba(255, 66, 88, 0.08)',
    },
    tab: '#353840',
    loader: 'rgba(0, 0, 0, 0.8)',
    darkBg: '#2E333A',
    active: '#00E676',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    boxShadowLarge:
      '0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);',
    ui2: '#2E333A',
    header: '#F86D7A',
    filter:
      'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
    uniswap: '#FF007A',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #2E333A',
          borderBottomStyle: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#81909E #141619',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#141619',
            width: 12,
            height: 12,
            position: 'fixed',
            bottom: 40,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#81909E',
            border: '2px solid #81909E',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#141619',
          },
        },
      },
    },
  },
});
