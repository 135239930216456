import { Box, styled } from '@mui/material';

import FromIcon from 'assets/season2/progress-from.svg';
import ToIcon from 'assets/season2/progress-to.svg';

const Container = styled(Box)(({ theme }) => ({
  height: 28,
  width: '100%',
  background: 'linear-gradient(340deg, rgba(0, 171, 88, 0.2) 9.32%, rgba(0, 230, 118, 0.2) 83.24%)',
  borderRadius: theme.spacing(3),
  backdropFilter: 'blur(150px)',
  position: 'relative',
  textAlign: 'center',
}));

const Bar = styled(Box)(({ theme, progress }) => ({
  background: `linear-gradient(270deg, ${theme.palette.primary.main} 2.96%, ${theme.palette.support.success} 96.87%)`,
  width: `calc(${progress}% - 4px)`,
  height: 28,
  borderRadius: theme.spacing(3),
  position: 'absolute',
  left: 2,
  top: 0,
  zIndex: 1,
}));

const LevelElipse = styled(Box)(({ background, left, right, opacity }) => ({
  width: 24,
  height: 24,
  position: 'absolute',
  zIndex: 1,
  borderRadius: '100%',
  backgroundSize: 'contain',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 500,
  backgroundImage: background,
  left,
  right,
  opacity,
}));

const TextContainer = styled(Box)(() => ({
  zIndex: 2,
  height: 28,
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.4px',
  fontFeatureSettings: `'cv02' on, 'ss01' on, 'cv03' on, 'cv04' on, 'cv09' on, 'cv11' on`,
}));

const Text = styled('span')(({ blured }) => ({
  fontSize: 12,
  fontWeight: 500,
  opacity: blured ? 0.5 : 1,
}));

const ProgressBar = ({ from, to, value, currentLevel = 0 }) => {
  const progress = (value / (to - from)) * 100;

  return (
    <Container>
      <Bar progress={progress} />
      <LevelElipse left={4} background={`url('${FromIcon}')`}>
        {currentLevel}
      </LevelElipse>
      <LevelElipse right={4} background={`url('${ToIcon}')`} opacity="0.3">
        {currentLevel + 1}
      </LevelElipse>
      <TextContainer>
        <Text>{value}</Text>
        <Text blured="true">/{to}</Text>
      </TextContainer>
    </Container>
  );
};

export default ProgressBar;
