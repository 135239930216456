export const getXOauthUrl = (address) => {
  const rootUrl = 'https://twitter.com/i/oauth2/authorize';
  const options = {
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}user/authenticateTwitter`,
    client_id: 'Vi04LVBWUjk3MDVFb2hEOHVlemI6MTpjaQ',
    state: address,
    response_type: 'code',
    code_challenge: '3kseEynu13O-8kZdzEy5RUHKmDBjwYMYfOaI_Sv5w3k',
    code_challenge_method: 'S256',
    scope: ['users.read', 'tweet.read', 'follows.read'].join(' '),
  };
  const qs = new URLSearchParams(options).toString();
  return `${rootUrl}?${qs}`;
};
