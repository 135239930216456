import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonOutlined = styled(Button)(({ textcolor, padding }) => ({
  border: `1px solid ${textcolor}`,
  backgroundColor: 'transparent',
  padding: padding ?? '8px 24px',
  borderRadius: 12,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 2,
  color: textcolor,
  margin: 4,

  '&:focus': {
    boxShadow: `0 0 0 1px ${textcolor}`,
  },
  '&:hover': {
    boxShadow: `0 0 0 1px ${textcolor}`,
  },
  '&:active': {
    boxShadow: `0 0 0 1px ${textcolor}`,
  },
  '&:disabled': {
    opacity: '50%',
    cursor: 'auto',
  },
}));

export const StandardButton = styled(Button)(({ theme, width = null, height = null }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.white,
  borderRadius: 8,
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(1, 2),
  lineHeight: '24px',
  letterSpacing: '0.15px',
  width: width,
  height: height,
  boxShadow: 'none',
  '&:hover': {
    background: theme.palette.background.button.hovered,
    boxShadow: 'none',
  },
  '&:active': {
    background: theme.palette.background.button.pressed,
    boxShadow: 'none',
  },
  '&:disabled': {
    background: theme.palette.background.button.pressed,
    color: theme.palette.text.white,
    opacity: 0.24,
  },
}));

export const StandardButtonNotOutlined = styled(Button)(
  ({ theme, width = null, height = null, margin }) => ({
    color: theme.palette.button.active,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    margin: margin ?? theme.spacing(0, 2),
    lineHeight: '24px',
    letterSpacing: '0.15px',
    width: width,
    height: height ?? 48,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      background: theme.palette.background.clear.hovered,
    },
    '&:active': {
      boxShadow: 'none',
      background: theme.palette.background.clear.pressed,
    },
    '&:disabled': {
      color: theme.palette.button.active,
      opacity: 0.6,
    },
  }),
);

export const GrayButtonOutlined = styled(Button)(
  ({ theme, width = null, height = null, padding }) => ({
    border: `1px solid ${theme.palette.border.decorative}`,
    background: theme.palette.background.default,
    padding: padding ?? '12x 16px',
    borderRadius: 8,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 2,
    width: width ?? '100%',
    height: height ?? 48,
    color: theme.palette.border.high,
    minWidth: 0,

    '&:hover': {
      background: theme.palette.background.linear,
    },
  }),
);

export const StandardWhiteButton = styled(Button)(({ theme, width = null, height = null }) => ({
  backgroundColor: '#FFFFFF',
  color: theme.palette.background.default,
  borderRadius: 12,
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(1, 2),
  lineHeight: '24px',
  letterSpacing: '0.15px',
  width: width,
  height: height,
  boxShadow: 'none',
  '&:hover': {
    background: '#DDDDDD',
    boxShadow: 'none',
  },
  '&:active': {
    background: '#CCCCCC',
    boxShadow: 'none',
  },
  '&:disabled': {
    background: '#808080',
    color: theme.palette.text.white,
    opacity: 0.24,
  },
}));

export const SimpleGreenTextButton = styled((props) => <Button disableRipple {...props} />)(
  ({ theme }) => ({
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.link.primary,
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 0,

    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
    },
    '&:active': {
      background: 'none',
    },
    '&:disabled': {
      textDecoration: 'none',
      color: theme.palette.link.primary,
      opacity: 0.5,
      cursor: 'pointer',
    },
  }),
);

export const SimpleTextButton = styled((props) => <Button disableRipple {...props} />)(
  ({ theme }) => ({
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.text.main,
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 0,

    '&:hover': {
      background: 'none',
    },
    '&:active': {
      background: 'none',
    },
    '&:disabled': {
      textDecoration: 'none',
      color: theme.palette.link.primary,
      opacity: 0.5,
      cursor: 'pointer',
    },
  }),
);
