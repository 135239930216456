import React from 'react';
import { IconButton, styled, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Text } from 'components/Text';
import { RefreshButton as RefreshIcon } from 'components/ImageComponent';
import { FlexCenter } from 'components/StyledComponents';

/********************  Styled Components  ********************/
const FlexBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,

  '@media (max-width:600px)': {
    marginTop: 16,
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
  },
}));

/********************  Main Component  ********************/
const RefreshButton = ({ lastUpdate, handleClick, isLoading, adjacentButton }) => {
  const below600 = useMediaQuery('(max-width:600px)');

  return (
    <FlexBox>
      <Text secondary="true" fs={10} fw={500} lh={15}>
        Last updated: {lastUpdate}m ago
      </Text>
      <FlexCenter gap={below600 ? 16 : 8} width={below600 ? '100%' : 'auto'}>
        <IconButton sx={{ p: 0 }} onClick={handleClick}>
          {isLoading ? <CircularProgress /> : <RefreshIcon />}
        </IconButton>
        {adjacentButton}
      </FlexCenter>
    </FlexBox>
  );
};

export default RefreshButton;
