import { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { signMessage } from '@wagmi/core';

import { SIGNATURE_EXPIRY_TIME, AUTH_MESSAGE_TO_SIGN } from 'constants/index';
import { config } from 'constants/walletConfig';
import userService from 'services/user';
import useNotification from 'hooks/useNotification';

const WalletContext = createContext();
export const useWallet = () => useContext(WalletContext);

/********************  Context Provider ********************/
export const WalletProvider = ({ children }) => {
  const { address: rawAddress, chainId, status } = useAccount();
  const { connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const showNotification = useNotification();

  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(['user']);
  const [twitterUser, setTwitterUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const metamask = connectors?.find((connector) => connector.name === 'MetaMask');
  const isConnected = Boolean(status === 'connected');
  const address = rawAddress?.toLowerCase();

  useEffect(() => {
    if (status !== 'reconnecting' && status !== 'connecting' && !isConnected) {
      connectWallet();
    }
  }, [status, isConnected]);

  useEffect(() => {
    const authenticate = async () => {
      if (address) {
        if (!checkAuthentication()) {
          await authenticateWallet();
        } else {
          setIsAuthenticated(true);
        }
      } else {
        cleanLocalStorage();
        disconnect();
        setIsAuthenticated(false);
      }
    };

    if (isConnected || status === 'reconnecting') {
      authenticate();
    } else {
      cleanLocalStorage();
      setIsAuthenticated(false);
    }

    if (address && !cookies.address) {
      setCookie('address', address, { path: '/' });
    }
  }, [address]);

  useEffect(() => {
    const checkTwitter = async () => {
      setLoading(true);
      const user = await userService.getTwitterUser(address);
      setTwitterUser(user);
      setLoading(false);
    };

    if (isAuthenticated) {
      checkTwitter();
    } else {
      // handle wallet lock
      setTwitterUser(null);
    }
  }, [isAuthenticated]);

  const connectWallet = async () => {
    try {
      if (metamask) {
        await metamask.connect();
      } else {
        setWalletModalOpen(true);
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: 'Please try again',
      });
      console.error(error);
    }
  };

  const checkAuthentication = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }

    const now = new Date();
    const tokenExpiry = parseInt(localStorage.getItem('token_expiry'));
    if (now.getTime() > tokenExpiry) {
      cleanLocalStorage();
      return false;
    }

    return localStorage.getItem('token_wallet') === address;
  };

  const authenticateWallet = async () => {
    try {
      // handles wallet switch
      setIsAuthenticated(false);

      const nonceData = await userService.getNonce(address);
      if (nonceData) {
        const message = AUTH_MESSAGE_TO_SIGN(address, nonceData.data.nonce);
        const sign = await signMessage(config, {
          account: address,
          message,
        });

        const authResponseData = await userService.authenticate(
          address,
          sign,
          localStorage.getItem('linked_main_wallet'),
        );
        if (authResponseData) {
          localStorage.setItem('token', authResponseData.data.auth.token);
          localStorage.setItem('token_wallet', address);
          localStorage.setItem('token_expiry', Date.now() + SIGNATURE_EXPIRY_TIME);
          localStorage.setItem('linked_wallets', JSON.stringify(authResponseData.data.linked));
          localStorage.setItem('linked_main_wallet', authResponseData.data.mainwallet);
          setIsAuthenticated(true);
          showNotification({
            type: 'success',
            message: 'Wallet connected successfully.',
          });
        }
      }
    } catch (e) {
      cleanLocalStorage();
      setIsAuthenticated(false);
    }
  };

  const cleanLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token_wallet');
    localStorage.removeItem('token_expiry');
  };

  const closeModal = () => setWalletModalOpen(false);

  return (
    <WalletContext.Provider
      value={{
        address,
        isConnected,
        chainId,
        walletModalOpen,
        twitterUser,
        connectWallet,
        closeModal,
        isAuthenticated,
        authenticateWallet,
        loading,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
