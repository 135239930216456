import { ROUTES } from 'pages/routes';
import {
  XLogo,
  InstagramIcon,
  DiscordIcon,
  RedditIcon,
  YouTubeIcon,
  FacebookIcon,
  LinkedInIcon,
  WhatsAppIcon,
  NRGTokenIcon,
  ETHTokenIcon,
  USDTTokenIcon,
  USDCTokenIcon,
  DAITokenIcon,
  PEPETokenIcon,
  XAirdropIcon,
  LiquidityAirdropIcon,
  CreatorAirdropIcon,
  TraderAirdropIcon,
  TokenAirdropIcon,
  NftAirdropIcon,
  LeaderboardsIcon,
  BoostersIcon,
  ZealyAirdropIcon,
  NftVolumeAirdropIcon,
  NftBridingAirdropIcon,
  NftPortfolioAirdropIcon,
  NftListingAirdropIcon,
} from 'components/ImageComponent';
import {
  CreatorDataRow,
  LiquidityDataRow,
  NftHodlerDataRow,
  TokenHodlerDataRow,
  TraderDataRow,
  XDataRow,
  NftVolumeDataRow,
  ZealyDataRow,
  NftPortfolioDataRow,
} from 'pages/Leaderboard/components/Table';

// Login
export const SIGNATURE_EXPIRY_TIME = 86400000; // 1 day
export const AUTH_MESSAGE_TO_SIGN = (address, nonce) => {
  return `Welcome to GMI!\n\nClick to sign in and accept the GMI Terms of Service\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address: ${address}\n\nNonce: ${nonce}`;
};

// Social Media
export const SOCIAL_MEDIAS = [
  {
    name: 'Twitter',
    link: 'https://x.com/energi',
    icon: <XLogo />,
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/energicrypto',
    icon: <InstagramIcon />,
  },
  {
    name: 'Discord',
    link: 'https://discord.com/invite/sCtgNC3/',
    icon: <DiscordIcon />,
  },
  {
    name: 'Reddit',
    link: 'https://www.reddit.com/r/energicryptocurrency/',
    icon: <RedditIcon />,
  },
  {
    name: 'Youtube',
    link: 'https://www.youtube.com/c/Energicrypto',
    icon: <YouTubeIcon />,
  },
];
export const SOCIAL_LINKS = {
  DiscordEnergi: 'https://discord.com/invite/sCtgNC3',
  DiscordDD: 'https://discord.gg/6nU4zyNxyH',
  TwitterDD: 'https://x.com/DragonDegensNFT',
  TwitterGMI: 'https://x.com/gonnamakeitnfts',
  TwitterEnergi: 'https://x.com/energi',
  TwitterTommy: 'https://x.com/TommyWorldPower',
  TwitterRyan: 'https://x.com/ryanlucchese',
  Telegram: 'https://t.me/GonnaMakeitNFTs',
};
export const SOCIAL_MEDIA_SHARE_INFO = [
  {
    name: 'X(Twitter)',
    background: '#FFFFFF',
    hovered: '#DDDDDD',
    active: '#CCCCCC',
    icon: <XLogo sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'Facebook',
    background: '#4267B2',
    hovered: '#567CCA',
    active: '#4366AC',
    icon: <FacebookIcon sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'LinkedIn',
    background: '#217BB0',
    hovered: '#358EC2',
    active: '#217BB0',
    icon: <LinkedInIcon sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'Reddit',
    background: '#FF5700',
    hovered: '#FF6617',
    active: '#F65806',
    icon: <RedditIcon sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'WhatsApp',
    background: '#25D366',
    hovered: '#3EE87D',
    active: '#1DCA5D',
    icon: <WhatsAppIcon sx={{ width: 32, height: 32 }} />,
  },
];
export const RP_X_SHARE_MESSAGE = `🚨Attention hodlers of $ETH, $PEPE, $SHIB, $UNI, & $USDC🚨\n\nYou’re eligible to claim the $GMI Airdrop for the LIVE @GonnamakeItNFTs marketplace!🎨✨\n\nHurry, less than 2 weeks left to claim!🚀\n\nUse my referral link and we both get a 30% BONUS🤑👇\n`;

// Email
export const EMAIL_VERIFICATION_DELAY = 60000; // 1 minute
export const EMAIL_LOCAL_STORAGE_ID = 'email';

// Links
export const GMI_LINKS = {
  ReferralProgram: 'https://docs.gonnamakeit.com/docs/about/gmi-referral',
  PrivacyPolicy: 'https://gonnamakeit.com/privacy-policy/',
  GetNRG: 'https://energi.world/get-nrg/',
  DragonDegen: 'https://energistudios.com/dragon-degens-nfts/',
  AirdropDocs: 'https://docs.gonnamakeit.com/docs/about/gmi-airdrop#5-eligibility-criteria',
  Website: 'https://gonnamakeit.com/',
  Webapp: 'https://app.gonnamakeit.com/',
};

export const LEGACY_BRIDGE_URL = 'https://bridge.energi.network';
export const MULTI_CHAIN_BRIDGE_URL = 'https://mbridge.energi.network';
export const METAMASK_URL = 'https://metamask.io/download.html';
export const METAMASK_GUIDE_URL =
  'https://wiki.energi.world/docs/quickstart/getting-started-with-metamask';
export const AIRDROP_DOCS_LINK = 'https://docs.gonnamakeit.com/docs/airdrop/gmi-airdrop-season-3';
export const ZEALY_URL = 'https://zealy.io/cw/energicrew/questboard';
export const REFERRAL_PROGRAM_URL = 'https://ref.gonnamakeit.com';
export const NFT_BRIDGE_URL = 'https://bridge.gonnamakeit.com/';

// UI
export const AIRDROP_MENUS = [
  {
    title: 'LEADERBOARDS',
    icon: <LeaderboardsIcon height="100%" />,
    to: ROUTES.Leaderboard,
  },
  {
    title: 'BOOSTERS',
    icon: <BoostersIcon height="100%" />,
    to: ROUTES.Boosters,
  },
  {
    title: 'MARKETPLACE VOLUME AIRDROP',
    icon: <NftVolumeAirdropIcon height="50px" />,
    to: ROUTES.NftVolumeAirdrop,
  },
  {
    title: 'TOKEN HODLER AIRDROP',
    icon: <TokenAirdropIcon height="100%" />,
    to: ROUTES.TokenHodler,
  },
  {
    title: 'NFT HODLER AIRDROP',
    icon: <NftAirdropIcon height="100%" />,
    to: ROUTES.NftHodler,
  },
  {
    title: 'LIQUIDITY AIRDROP',
    icon: <LiquidityAirdropIcon height="100%" />,
    to: ROUTES.LiquidityAirdrop,
  },
  {
    title: 'X AIRDROP',
    icon: <XAirdropIcon height="100%" />,
    to: ROUTES.XDashboard,
  },
  {
    title: 'LISTING AIRDROP',
    icon: <NftListingAirdropIcon height="100%" />,
    to: ROUTES.NftListingAirdrop,
  },
  {
    title: 'PORTFOLIO VALUE AIRDROP',
    icon: <NftPortfolioAirdropIcon height="100%" />,
    to: ROUTES.NftPortfolioAirdrop,
  },
  {
    title: 'TRADER AIRDROP',
    icon: <TraderAirdropIcon height="100%" />,
    to: ROUTES.TraderAirdrop,
  },
  {
    title: 'CREATOR AIRDROP',
    icon: <CreatorAirdropIcon height="100%" />,
    to: ROUTES.CreatorAirdrop,
  },
  {
    title: 'ZEALY AIRDROP',
    icon: <ZealyAirdropIcon height="100%" />,
    to: ROUTES.ZealyAirdrop,
  },
  {
    title: 'NFT BRIDGING AIRDROP',
    icon: <NftBridingAirdropIcon height="100%" />,
    to: ROUTES.NftBridgeAirdrop,
  },
];
export const TOKEN_ICONS = {
  NRG: <NRGTokenIcon width={22} height={22} />,
  ETH: <ETHTokenIcon width={22} height={22} />,
  USDT: <USDTTokenIcon width={22} height={22} />,
  USDC: <USDCTokenIcon width={22} height={22} />,
  DAI: <DAITokenIcon width={22} height={22} />,
  PEPE: <PEPETokenIcon width={22} height={22} />,
};

// Airdrop
export const AIRDROP_SEASON = 3;
export const AIRDROP_MILLION_GMI = 1;
export const AIRDROP_START_DATE = new Date(1725580800000); // September 6, 2024 12:00:00 AM
export const AIRDROP_FINISH_DATE = new Date(1727740800000); // October 1, 2024 12:00:00 AM
export const BRIDGED_TOKENS = ['ETH', 'USDT', 'USDC', 'DAI', 'PEPE'];
export const LIQUIDITY_PAIRS = {
  testnet: {
    Energi: [
      {
        address: '0x47e9f80f425884b384a70da81fe6db8685996cca',
        token0: 'NRG',
        token1: 'ETH',
        token1Address: '0x01a5534f82Ab54e1F37Ee585F3049Cf8B80f79A4',
      },
      {
        address: '0x3a0eeaa0033f12504d03364dae19af67b8098a1c',
        token0: 'NRG',
        token1: 'USDT',
        token1Address: '0xc52C33571EfFfeb77Fc8b45B5c9c142723bF92d8',
      },
      {
        address: '0xb386c099059d2e4f96ca88bab93d07da2f324dd8',
        token0: 'NRG',
        token1: 'USDC',
        token1Address: '0x271268cebeB310D8B19F088aDEBF3f247910562c',
      },
      {
        address: '0x9e752bb585c870e4161be5f5e09dc1ba2a122ee9',
        token0: 'NRG',
        token1: 'DAI',
        token1Address: '0x3e798553502042088783B83e4B62e22152E8717e',
      },
      {
        address: '0x0000000000000000000000000000000000000000',
        token0: 'NRG',
        token1: 'PEPE',
        token1Address: '0x0000000000000000000000000000000000000000',
      },
      {
        address: '0x0000000000000000000000000000000000000000',
        token0: 'NRG',
        token1: 'PEPE',
        token1Address: '0x0000000000000000000000000000000000000000',
      },
    ],
    Ethereum: [
      {
        token0: 'ETH',
        token1: 'NRG',
        address: '0x0000000000000000000000000000000000000000',
        token1Address: '0x0000000000000000000000000000000000000000',
      },
    ],
  },
  mainnet: {
    Energi: [
      {
        address: '0xb8477E82CbFB664B465979A7819b7F640880277c',
        token0: 'NRG',
        token1: 'ETH',
        token1Address: '0x78B050d981d7f6E019Bf6E361D0d1167de6B19dA',
      },
      {
        address: '0x3F0a1D7C138C18CdB4B9A51Ec932d217e7b80942',
        token0: 'NRG',
        token1: 'USDT',
        token1Address: '0x470075Cf46e6132aaD78C40a1BE53a494b05E831',
      },
      {
        address: '0xf3963f014b8525D7B79D2491aD40B83fB37CCC63',
        token0: 'NRG',
        token1: 'USDC',
        token1Address: '0xFFD7510ca0a3279c7a5F50018A26c21d5bc1DBcF',
      },
      {
        address: '0x5Cc8B3D89AE2cA30A08b6697E33606FC3437d392',
        token0: 'NRG',
        token1: 'DAI',
        token1Address: '0x0ee5893f434017d8881750101Ea2F7c49c0eb503',
      },
      {
        address: '0xacA8dC617f4828693FdC22240b1530700caFE4c3',
        token0: 'NRG',
        token1: 'PEPE',
        token1Address: '0x52B16632A9ed3977D7D701108bD548Ce693b610c',
      },
    ],
    Ethereum: [
      {
        token0: 'NRG',
        token1: 'ETH',
        address: '0xd2624380a006220574c9e75083490b042e71ee42',
        token1Address: '0x1416946162B1C2c871A73B07E932D2fB6C932069',
      },
    ],
  },
}[process.env.REACT_APP_ENVIRONMENT ?? 'mainnet'];
export const LIQUIDITY_POOL_LINKS = {
  testnet: {
    Energi: 'https://app.test.energiswap.exchange/#/add/',
    Ethereum: 'https://app.uniswap.org/add/',
  },
  mainnet: {
    Energi: 'https://app.energiswap.exchange/#/add/',
    Ethereum: 'https://app.uniswap.org/add/',
  },
}[process.env.REACT_APP_ENVIRONMENT ?? 'mainnet'];
export const CHAIN = {
  mainnet: {
    Energi: 39797,
    Ethereum: 1,
  },
  testnet: {
    Energi: 49797,
    Ethereum: 1,
  },
}[process.env.REACT_APP_ENVIRONMENT ?? 'mainnet'];
export const LEADERBOARDS = [
  {
    key: 'nftVolume',
    value: 'Marketplace Vol Airdrop Leaderboard',
    menuTitle: 'Marketplace Vol Airdrop',
    row: NftVolumeDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Diamonds)' },
    ],
  },
  {
    key: 'token',
    value: 'Token Hodler Airdrop Leaderboard',
    menuTitle: 'Token Hodler Airdrop',
    row: TokenHodlerDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Rubies)' },
    ],
  },
  {
    key: 'nft',
    value: 'NFT Hodler Airdrop Leaderboard',
    menuTitle: 'NFT Hodler Airdrop',
    row: NftHodlerDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Aether)' },
    ],
  },
  {
    key: 'liquidity',
    value: 'Liquidity Airdrop Leaderboard',
    menuTitle: 'Liquidity',
    row: LiquidityDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'liquidity', label: 'Total Liquidity', hideOnMobile: true },
      { id: 'score', label: 'Score (Sapphires)' },
    ],
  },
  {
    key: 'x',
    value: 'X Airdrop Leaderboard',
    menuTitle: 'X',
    row: XDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'twitterHandle', label: 'Twitter Handle', align: 'left' },
      { id: 'tweets', label: 'Tweets', hideOnMobile: true },
      { id: 'views', label: 'Views', hideOnMobile: true },
      { id: 'score', label: 'Score (Amethysts)' },
    ],
  },
  {
    key: 'portfolio',
    value: 'Portfolio Value Airdrop Leaderboard',
    menuTitle: 'Portfolio Value Airdrop',
    row: NftPortfolioDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Emeralds)' },
    ],
  },
  {
    key: 'trader',
    value: 'Trader Airdrop Leaderboard',
    menuTitle: 'Trader Airdrop',
    row: TraderDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'volume', label: 'Total Volume', hideOnMobile: true },
      { id: 'score', label: 'Score (Topaz)' },
    ],
  },
  {
    key: 'creator',
    value: 'Creator Airdrop Leaderboard',
    menuTitle: 'Creator Airdrop',
    row: CreatorDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'collection', label: 'Collection Name', align: 'left' },
      { id: 'volume', label: 'Total Volume', hideOnMobile: true },
      { id: 'score', label: 'Score (Quartz)' },
    ],
  },
  {
    key: 'zealy',
    value: 'Zealy Airdrop Leaderboard',
    menuTitle: 'Zealy Airdrop',
    row: ZealyDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'username', label: 'Username', align: 'left' },
      { id: 'score', label: 'Score (Pearls)' },
    ],
  },
];
export const NFT_HODLER = [
  {
    name: 'Bored Ape Yacht Club',
    title: 'BAYC Hodler Airdrop',
    pointsTitle: [
      'BAYC Hodler Base Aether:',
      'BAYC Hodler Booster Aether:',
      'BAYC Hodler Referral Aether:',
      'BAYC Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Mutant Ape Yacht Club',
    title: 'MAYC Hodler Airdrop',
    pointsTitle: [
      'MAYC Hodler Base Aether:',
      'MAYC Hodler Booster Aether:',
      'MAYC Hodler Referral Aether:',
      'MAYC Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Quirkies',
    title: 'Quirkies Hodler Airdrop',
    pointsTitle: [
      'Quirkies Hodler Base Aether:',
      'Quirkies Hodler Booster Aether:',
      'Quirkies Hodler Referral Aether:',
      'Quirkies Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Wumbo Pass',
    title: 'Wumbologists Hodler Airdrop',
    pointsTitle: [
      'Wumbologists Hodler Base Aether:',
      'Wumbologists Hodler Booster Aether:',
      'Wumbologists Hodler Referral Aether:',
      'Wumbologists Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Nonconformist Ducks',
    title: 'NoncoDucks Hodler Airdrop',
    pointsTitle: [
      'NoncoDucks Hodler Base Aether:',
      'NoncoDucks Hodler Booster Aether:',
      'NoncoDucks Hodler Referral Aether:',
      'NoncoDucks Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
];
export const TOKEN_HODLER = [
  {
    name: 'Ethereum',
    title: 'ETH Hodler Airdrop',
    pointsTitle: [
      'ETH Hodler Base Rubies:',
      'ETH Hodler Booster Rubies:',
      'ETH Hodler Referral Rubies:',
      'ETH Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Uniswap',
    title: 'Uniswap Hodler Airdrop',
    pointsTitle: [
      'Uniswap Hodler Base Rubies:',
      'Uniswap Hodler Booster Rubies:',
      'Uniswap Hodler Referral Rubies:',
      'Uniswap Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'USDC',
    title: 'USDC Hodler Airdrop',
    pointsTitle: [
      'USDC Hodler Base Rubies:',
      'USDC Hodler Booster Rubies:',
      'USDC Hodler Referral Rubies:',
      'USDC Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Pepe',
    title: 'PEPE Hodler Airdrop',
    pointsTitle: [
      'PEPE Hodler Base Rubies:',
      'PEPE Hodler Booster Rubies:',
      'PEPE Hodler Referral Rubies:',
      'PEPE Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Shiba Inu',
    title: 'SHIB Hodler Airdrop',
    pointsTitle: [
      'SHIB Hodler Base Rubies:',
      'SHIB Hodler Booster Rubies:',
      'SHIB Hodler Referral Rubies:',
      'SHIB Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Mog Coin',
    title: 'MOG Hodler Airdrop',
    pointsTitle: [
      'MOG Hodler Base Rubies:',
      'MOG Hodler Booster Rubies:',
      'MOG Hodler Referral Rubies:',
      'MOG Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Chain Link',
    title: 'ChainLink Hodler Airdrop',
    pointsTitle: [
      'ChainLink Hodler Base Rubies:',
      'ChainLink Hodler Booster Rubies:',
      'ChainLink Hodler Referral Rubies:',
      'ChainLink Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Balancer',
    title: 'Balancer Hodler Airdrop',
    pointsTitle: [
      'Balancer Hodler Base Rubies:',
      'Balancer Hodler Booster Rubies:',
      'Balancer Hodler Referral Rubies:',
      'Balancer Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Decentraland',
    title: 'Decentraland Hodler Airdrop',
    pointsTitle: [
      'Decentraland Hodler Base Rubies:',
      'Decentraland Hodler Booster Rubies:',
      'Decentraland Hodler Referral Rubies:',
      'Decentraland Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Aave Token',
    title: 'AAVE Hodler Airdrop',
    pointsTitle: [
      'AAVE Hodler Base Rubies:',
      'AAVE Hodler Booster Rubies:',
      'AAVE Hodler Referral Rubies:',
      'AAVE Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Compound',
    title: 'Compound Hodler Airdrop',
    pointsTitle: [
      'Compound Hodler Base Rubies:',
      'Compound Hodler Booster Rubies:',
      'Compound Hodler Referral Rubies:',
      'Compound Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Lido DAO Token',
    title: 'Lido Hodler Airdrop',
    pointsTitle: [
      'Lido Hodler Base Rubies:',
      'Lido Hodler Booster Rubies:',
      'Lido Hodler Referral Rubies:',
      'Lido Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Curve DAO Token',
    title: 'Curve Hodler Airdrop',
    pointsTitle: [
      'Curve Hodler Base Rubies:',
      'Curve Hodler Booster Rubies:',
      'Curve Hodler Referral Rubies:',
      'Curve Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Blast',
    title: 'BLAST Hodler Airdrop',
    pointsTitle: [
      'BLAST Hodler Base Rubies:',
      'BLAST Hodler Booster Rubies:',
      'BLAST Hodler Referral Rubies:',
      'BLAST Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Blur',
    title: 'BLUR Hodler Airdrop',
    pointsTitle: [
      'BLUR Hodler Base Rubies:',
      'BLUR Hodler Booster Rubies:',
      'BLUR Hodler Referral Rubies:',
      'BLUR Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Trump',
    title: 'Trump Hodler Airdrop',
    pointsTitle: [
      'Trump Hodler Base Rubies:',
      'Trump Hodler Booster Rubies:',
      'Trump Hodler Referral Rubies:',
      'Trump Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Turbo',
    title: 'Turbo Hodler Airdrop',
    pointsTitle: [
      'Turbo Hodler Base Rubies:',
      'Turbo Hodler Booster Rubies:',
      'Turbo Hodler Referral Rubies:',
      'Turbo Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Floki',
    title: 'Floki Hodler Airdrop',
    pointsTitle: [
      'Floki Hodler Base Rubies:',
      'Floki Hodler Booster Rubies:',
      'Floki Hodler Referral Rubies:',
      'Floki Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'The Balkan Dwarf',
    title: 'KeKec Hodler Airdrop',
    pointsTitle: [
      'KeKec Hodler Base Rubies:',
      'KeKec Hodler Booster Rubies:',
      'KeKec Hodler Referral Rubies:',
      'KeKec Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'LooksRare Token',
    title: 'LooksRare Hodler Airdrop',
    pointsTitle: [
      'LooksRare Hodler Base Rubies:',
      'LooksRare Hodler Booster Rubies:',
      'LooksRare Hodler Referral Rubies:',
      'LooksRare Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'X2Y2Token',
    title: 'X2Y2 Hodler Airdrop',
    pointsTitle: [
      'X2Y2 Hodler Base Rubies:',
      'X2Y2 Hodler Booster Rubies:',
      'X2Y2 Hodler Referral Rubies:',
      'X2Y2 Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Ethereum Name Service',
    title: 'Ens Hodler Airdrop',
    pointsTitle: [
      'Ens Hodler Base Rubies:',
      'Ens Hodler Booster Rubies:',
      'Ens Hodler Referral Rubies:',
      'Ens Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Gnosis',
    title: 'Gnosis Hodler Airdrop',
    pointsTitle: [
      'Gnosis Hodler Base Rubies:',
      'Gnosis Hodler Booster Rubies:',
      'Gnosis Hodler Referral Rubies:',
      'Gnosis Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Pooh',
    title: 'Pooh Hodler Airdrop',
    pointsTitle: [
      'Pooh Hodler Base Rubies:',
      'Pooh Hodler Booster Rubies:',
      'Pooh Hodler Referral Rubies:',
      'Pooh Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'MongCoin',
    title: 'MongCoin Hodler Airdrop',
    pointsTitle: [
      'MongCoin Hodler Base Rubies:',
      'MongCoin Hodler Booster Rubies:',
      'MongCoin Hodler Referral Rubies:',
      'MongCoin Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Maker',
    title: 'Maker Hodler Airdrop',
    pointsTitle: [
      'Maker Hodler Base Rubies:',
      'Maker Hodler Booster Rubies:',
      'Maker Hodler Referral Rubies:',
      'Maker Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Graph Token',
    title: 'The Graph Hodler Airdrop',
    pointsTitle: [
      'The Graph Hodler Base Rubies:',
      'The Graph Hodler Booster Rubies:',
      'The Graph Hodler Referral Rubies:',
      'The Graph Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'SAND',
    title: 'Sandbox Hodler Airdrop',
    pointsTitle: [
      'Sandbox Hodler Base Rubies:',
      'Sandbox Hodler Booster Rubies:',
      'Sandbox Hodler Referral Rubies:',
      'Sandbox Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'SushiToken',
    title: 'Sushi Hodler Airdrop',
    pointsTitle: [
      'Sushi Hodler Base Rubies:',
      'Sushi Hodler Booster Rubies:',
      'Sushi Hodler Referral Rubies:',
      'Sushi Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
];
export const NFT_VOLUME = [
  {
    name: 'opensea',
    title: 'OpenSea Volume Airdrop',
    pointsTitle: [
      'OpenSea Volume Base Diamonds:',
      'OpenSea Volume Booster Diamonds:',
      'OpenSea Volume Referral Diamonds:',
      'OpenSea Volume Total Diamonds:',
    ],
    values: [null, null, null, null],
  },
];
export const LEADERBOARD_RANKING_REWARDS = [
  {
    rank: '1st Place',
    reward: '100K',
  },
  {
    rank: '2nd Place',
    reward: '69K',
  },
  {
    rank: '3rd Place',
    reward: '50K',
  },
  {
    rank: '4th Place',
    reward: '40K',
  },
  {
    rank: '5th Place',
    reward: '35K',
  },
  {
    rank: '6th Place',
    reward: '30K',
  },
  {
    rank: '7th Place',
    reward: '25K',
  },
  {
    rank: '8th Place',
    reward: '20K',
  },
  {
    rank: '9th Place',
    reward: '15K',
  },
  {
    rank: '10th Place',
    reward: '10K',
  },
];
export const END_SEASON_POINTS = [
  {
    name: 'X',
    title: 'X Airdrop',
    pointsTitle: [
      'X Airdrop Base Points:',
      'X Airdrop Booster Points:',
      'X Airdrop Referral Points:',
      'X Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Liquidity',
    title: 'Liquidity Airdrop',
    pointsTitle: [
      'Liquidity Airdrop Base Points:',
      'Liquidity Airdrop Booster Points:',
      'Liquidity Airdrop Referral Points:',
      'Liquidity Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Trader',
    title: 'Trader Airdrop',
    pointsTitle: [
      'Trader Airdrop Base Points:',
      'Trader Airdrop Booster Points:',
      'Trader Airdrop Referral Points:',
      'Trader Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Creator',
    title: 'Creator Airdrop',
    pointsTitle: [
      'Creator Airdrop Base Points:',
      'Creator Airdrop Booster Points:',
      'Creator Airdrop Referral Points:',
      'Creator Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Token Hodler',
    title: 'Token Hodler Airdrop',
    pointsTitle: [
      'Token Hodler Base Points:',
      'Token Hodler Booster Points:',
      'Token Hodler Referral Points:',
      'Token Hodler Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'NFT Hodler',
    title: 'NFT Hodler Airdrop',
    pointsTitle: [
      'NFT Hodler Base Points:',
      'NFT Hodler Booster Points:',
      'NFT Hodler Referral Points:',
      'NFT Hodler Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'NFT Volume',
    title: 'NFT Volume Airdrop',
    pointsTitle: [
      'NFT Volume Base Points:',
      'NFT Volume Booster Points:',
      'NFT Volume Referral Points:',
      'NFT Volume Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Zealy',
    title: 'Zealy Airdrop',
    pointsTitle: [
      'Zealy Airdrop Base Points:',
      'Zealy Airdrop Booster Points:',
      'Zealy Airdrop Referral Points:',
      'Zealy Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
];

// Other
export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
