import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Box, Typography } from '@mui/material';

import { GMI_LINKS } from 'constants';
import { useWallet } from 'context/WalletContext';
import { ReferralProgramProvider } from 'context/ReferralProgramContext';
import { openLink } from 'utils/openLink';

import { StandardButton, ConnectButton } from 'components/Button';
import {
  PromotionCard,
  BonusAmountText,
  ReferralLinkModal,
  Container,
} from 'components/PromotionCard';
import { GreenHeartIcon } from 'components/ImageComponent';
import { ActivatedButton } from 'components/StyledComponents';
import { GreenSpanText } from 'components/Text';

/********************  Main Component  ********************/
const ReferralBonusCard = ({ completed, refetchData }) => {
  const [modalOpen, setModalOpen] = useStateIfMounted(false);
  const { isAuthenticated } = useWallet();

  return (
    <>
      <PromotionCard
        Icon={GreenHeartIcon}
        title="Referral Bonus"
        tooltip="Earn a 30% bonus for applying a referral code to your account."
        completed={completed}
      >
        <Container>
          {completed ? (
            <Box>
              <Typography fontSize={14} fontWeight={500} marginTop={0.5}>
                Referral Link confirmed successfully!
              </Typography>
              <Typography fontSize={12} fontWeight={400} marginTop={1}>
                You can now trade NFTs on{' '}
                <GreenSpanText
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => openLink(GMI_LINKS.Webapp)}
                >
                  GMI
                </GreenSpanText>{' '}
                with 10% Rebate.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography fontSize={12} fontWeight={500} marginBottom={1.5}>
                Enter your referral link to activate this bonus.
              </Typography>
              <Box>
                <BonusAmountText value={30} />
              </Box>
            </Box>
          )}
          {!isAuthenticated ? (
            <ConnectButton height={40} />
          ) : completed ? (
            <ActivatedButton>30% BONUS Activated</ActivatedButton>
          ) : (
            <StandardButton fullWidth onClick={() => setModalOpen(true)}>
              Activate Bonus
            </StandardButton>
          )}
        </Container>
      </PromotionCard>
      <ReferralProgramProvider>
        <ReferralLinkModal
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          refetchData={refetchData}
        />
      </ReferralProgramProvider>
    </>
  );
};

export default ReferralBonusCard;
