import React from 'react';
import { styled } from '@mui/material';

/********************  Styled Components  ********************/
const StyledProgressBar = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  height: 34,
  borderRadius: 8,
  overflow: 'hidden',
  alignItems: 'center',
}));

const BackText = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.support.success,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  color: 'black',
  height: 33,
  fontWeight: 600,
  borderRadius: 10,
}));

const FrontText = styled('div')(({ percentage }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  inset: 0,
  background: '#31373a',
  fontWeight: 600,
  color: 'white',
  clipPath: `inset(0 0 0 ${percentage}%)`,
  WebkitClipPath: `inset(0 0 0 ${percentage}%)`,
  transition: 'clip-path 1s linear',
}));

/********************  Main Component  ********************/
const ProgressBar = ({ percentage = 0, children }) => {
  return (
    <StyledProgressBar>
      {percentage > 0 && <BackText>{children}</BackText>}
      <FrontText percentage={percentage}>{children}</FrontText>
    </StyledProgressBar>
  );
};

export default ProgressBar;
