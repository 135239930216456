import { Box, styled } from '@mui/material';

import { ZEALY_BONUS } from 'constants/bonusS2';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide4 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>X Airdrop Bonus</ModalHeading>
        <ModalText>
          By performing special tasks, you will activate bonuses that boost your base X Airdrop
          points! Earn extra points through:
        </ModalText>
        <br />
        <UnorderedList>
          <li>
            <ModalText>
              <strong>NFT Hodlers Bonus:</strong> Get bonus points for holding specific NFTs.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Zealy Bonus:</strong> Join Energi Zealy crew for a {ZEALY_BONUS}% bonus.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Aped-in Bonus:</strong> Complete follow and join activities for ranking-based
              bonuses.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Early Degen Bonus:</strong> Sign up for the newsletter early & activate 30%
              bonus.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Referral Bonus:</strong> Earn points for referring others.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Spread the Word Bonus:</strong> Refer more people for higher bonuses.
            </ModalText>
          </li>
        </UnorderedList>
      </Box>
      <ImageBox imagewidth={316}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide4;
