import { format, sub } from 'date-fns';

export const getLastSevenDaysRange = () => {
  const today = new Date();
  const firstDay = format(sub(today, { days: 7 }), 'MMM dd');
  const lastDay = format(sub(today, { days: 1 }), 'MMM dd, yyyy');
  return `${firstDay} - ${lastDay}`;
};

export const getLastSevenWeeksRange = () => {
  const today = new Date();
  const firstDay = format(sub(today, { days: 50 }), 'MMM dd');
  const lastDay = format(sub(today, { days: 1 }), 'MMM dd, yyyy');
  return `${firstDay} - ${lastDay}`;
};

export const formatChartDate = (time) => new Date(time).toISOString().slice(0, 10);

export const getTooltipDate = ({ time, timeStamp }) => {
  const timeUTC = new Date(time.replace(/-/g, '/'));
  const endDate = new Date(timeUTC).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  const firstDay = format(sub(new Date(timeUTC), { days: 6 }), 'MMM dd');

  if (timeStamp === 'days') {
    return endDate;
  }
  return `${firstDay} - ${endDate}`;
};
