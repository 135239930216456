import React from 'react';
import { Typography, styled, Box, IconButton } from '@mui/material';
import { useStateIfMounted } from 'use-state-if-mounted';

import { formatRemainingTime } from 'utils/time';
import { EARLY_DEGEN_LEVELS, getEarlyDegenLevel } from 'constants/bonus';
import { getXOauthUrl } from 'utils/x';
import userService from 'services/user';
import { useWallet } from 'context/WalletContext';

import { EarlyDegenIcon, TimerIcon, GreenCheckIcon, InfoIcon } from 'components/ImageComponent';
import {
  Container,
  FlexBox,
  CongratulationsModal,
  EarlyDegenModal,
} from 'components/PromotionCard';
import { FlexCenter, LevelProgressDot, ActivatedButton } from 'components/StyledComponents';
import { Text } from 'components/Text';
import InfoModal from './components/InfoModal';
import { ConnectButton, StandardButton } from 'components/Button';

/********************  Styled Components  ********************/
const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: 328,
  width: '100%',
  height: 192,
  borderRadius: 8,
  border: `1px solid ${theme.palette.border.decorative}`,
  overflow: 'hidden',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.filter,
  position: 'relative',
}));

const CardHeader = styled(Box)(({ theme }) => ({
  height: 64,
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.border.decorative}`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.filter,
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
}));

const CheckIconButton = styled((props) => <IconButton disabled disableRipple {...props} />)(
  ({ theme }) => ({
    height: 32,
    width: 32,
    borderRadius: 100,
    background: '#141619',
    padding: 0,
    border: `2px solid ${theme.palette.border.decorative}`,
    cursor: 'default',
  }),
);

const Flex = styled('div')(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  justifyContent: 'center',
}));

const FlexDots = styled('div')(() => ({
  display: 'flex',
  gap: 4,
  cursor: 'default',
  alignItems: 'center',
}));

const FlexBonus = styled('div')(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'end',
}));

/********************  Main Component  ********************/
const EarlyDegenCard = ({ completed, multiplier, level, expiresIn, refetchData }) => {
  const [showInfoModal, setShowInfoModal] = useStateIfMounted(false);
  const [congratsModalOpen, setCongratsModalOpen] = useStateIfMounted(false);
  const [earlyDegenBoost, setEarlyDegenBoost] = useStateIfMounted(0);
  const [earlyDegenDialogOpen, setEarlyDegenDialogOpen] = useStateIfMounted(false);
  const { isAuthenticated, twitterUser, address } = useWallet();

  const xLink = getXOauthUrl(address);

  if (completed) {
    level = getEarlyDegenLevel(parseInt(multiplier * 100 - 100));
  }

  const time = formatRemainingTime(expiresIn);
  const percentage = completed ? (multiplier - 1) * 100 : EARLY_DEGEN_LEVELS[level].percentage;

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const handleActivateBonus = async () => {
    const isEarlyDegenUpdated = await userService.checkEarlyDegen();
    if (isEarlyDegenUpdated.success) {
      setEarlyDegenBoost(isEarlyDegenUpdated.boost);
      setCongratsModalOpen(true);
    } else {
      setEarlyDegenDialogOpen(true);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CheckIconButton>{completed ? <GreenCheckIcon width={40} /> : null}</CheckIconButton>
          <EarlyDegenIcon width={24} height={24} />
          <FlexBox>
            <FlexCenter gap={11}>
              <Text fs={16} fw={500} lh={28}>
                Early Degen Bonus
              </Text>
              <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => setShowInfoModal(true)} />
            </FlexCenter>
          </FlexBox>
        </CardHeader>
        <Content>
          <Container>
            <FlexBox>
              <FlexDots>
                {Array(EARLY_DEGEN_LEVELS.maxLevel)
                  .fill(null)
                  .map((_, index) => (
                    <FlexDots key={index}>
                      {completed ? (
                        index === level ? (
                          <LevelProgressDot green>
                            <GreenCheckIcon width="20" height="20" />
                          </LevelProgressDot>
                        ) : (
                          <LevelProgressDot green={index < level} />
                        )
                      ) : (
                        <LevelProgressDot yellow={index <= level} />
                      )}
                    </FlexDots>
                  ))}
              </FlexDots>
              <Typography fontSize={12} fontWeight={500}>
                {!completed && 'ROUND EXPIRES IN:'}
              </Typography>
            </FlexBox>
            <FlexBox marginTop={1}>
              {completed ? (
                <>
                  <FlexBonus>
                    <Text fs={16} lh={24} fw={400}>
                      Tier {EARLY_DEGEN_LEVELS[level].tier} Early Degen Bonus Locked In!
                    </Text>
                  </FlexBonus>
                </>
              ) : (
                <>
                  <FlexBonus>
                    <Text fs={20} fw={600} lh={24} md={{ fs: 28, fw: 500 }}>
                      {percentage}%
                    </Text>
                    <Text fs={12} lh={18} fw={500}>
                      BONUS
                    </Text>
                  </FlexBonus>
                  <Flex>
                    <TimerIcon />
                    {time}
                  </Flex>
                </>
              )}
            </FlexBox>
            {!isAuthenticated ? (
              <ConnectButton height={40} />
            ) : !twitterUser ? (
              <StandardButton href={xLink}>Sign in with 𝕏</StandardButton>
            ) : completed ? (
              <ActivatedButton>{percentage.toFixed(0)}% BONUS Activated</ActivatedButton>
            ) : (
              <StandardButton onClick={handleActivateBonus}>Activate Bonus</StandardButton>
            )}
          </Container>
        </Content>
      </Card>
      {showInfoModal && <InfoModal onClose={handleCloseInfoModal} />}
      {congratsModalOpen && (
        <CongratulationsModal
          isOpen={congratsModalOpen}
          closeModal={refetchData}
          title={`Early Degen booster successfully activated!`}
          subTitle="Navigate to Boosters to unlock even bigger rewards"
          bonuses={[{ icon: EarlyDegenIcon, name: 'Early Degen Booster', amount: earlyDegenBoost }]}
        />
      )}
      {earlyDegenDialogOpen && (
        <EarlyDegenModal
          isOpen={earlyDegenDialogOpen}
          onDismiss={() => setEarlyDegenDialogOpen(false)}
        />
      )}
    </>
  );
};

export default EarlyDegenCard;
