import { Box, Typography, styled } from '@mui/material';

import Modal from 'components/Modal';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'left',
  width: '100%',
}));

const ReferralProgramModal = ({ onClose }) => {
  return (
    <Modal title="Referral Program" isOpen={true} onDismiss={onClose} width={524}>
      <Container>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          Every time someone uses your referral link, you will reserve 30% of their base reward
          (Level 1 Referee). For any of your Level 1 Referees who secure their own referrals, you
          earn an additional 20% of the base reward of the Level 2 Referee. If the Level 2 Referee
          gets their own batch of referrals to sign up to the airdrop, you reserve another 10% of
          the Level 3 Referee&apos;s base reward. There&apos;s no cap on how many people a user can
          their referral group. Note that this bonus applies across all airdrop activities!
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={2}>
          Level 1 Referee - 30%
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          Level 2 Referee - 20%
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          Level 3 Referee - 10%
        </Typography>
      </Container>
    </Modal>
  );
};

export default ReferralProgramModal;
