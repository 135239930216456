import {
  EarlyDegenCard,
  ApedCard,
  SpreadTheWordCard,
  DiamondBonusCard,
  ReferralBonusCard,
  TweetBonusCard,
} from 'components/PromotionCard';

import {
  ApedIcon,
  EarlyDegenIcon,
  GreenHeartIcon,
  SpreadWordIcon,
  BirdIcon,
  DiamondIcon,
  LooksRareIcon,
  ShipIcon,
  SushiIcon,
  UnicornIcon,
  X2Y2Icon,
  ETHStackerIcon,
} from 'components/ImageComponent';

export const CURRENT_SEASON = 3;

export const SEASONS = {
  2: {
    start: 1720080000000, // UTC Thursday, July 4, 2024 8:00:00 AM
    end: 1725436800000, // UTC Wednesday, September 4, 2024 8:00:00 AM
  },
  3: {
    start: 1725580800000, // UTC Friday, September 6, 2024 12:00:00 AM
    end: 1727740800000, // UTC Tuesday, October 1, 2024 12:00:00 AM
  },
};

export const BASE_REWARDS = [
  {
    name: 'NRG Diamond Hands',
    amount: 0,
    id: 'nrgRewardGmi',
    isReady: true,
    icon: DiamondIcon,
    tooltip:
      'Powerful rewards for the NRG Diamond Hands! Purchase and hold NRG through the token generation event to receive big GMI Base reward bonuses!',
  },
  {
    name: 'OpenSea Reward',
    amount: 0,
    id: 'eligibleGmi',
    isReady: true,
    icon: ShipIcon,
    tooltip: 'Based on your OpenSea volume',
  },
  {
    name: 'ETH Stackers Reward',
    amount: 0,
    id: 'ethStackerGmi',
    isReady: true,
    icon: ETHStackerIcon,
    tooltip: 'Based on your ETH balance',
  },
  {
    name: 'LooksRare Reward',
    amount: 0,
    id: 'looksGmi',
    isReady: true,
    icon: LooksRareIcon,
    tooltip: 'Based on your LOOKS balance',
  },
  {
    name: 'X2Y2 Reward',
    amount: 0,
    id: 'x2y2Gmi',
    isReady: true,
    icon: X2Y2Icon,
    tooltip: 'Based on your X2Y2 balance',
  },
  {
    name: 'Sushiswap Reward',
    amount: 0,
    id: 'sushiswapGmi',
    isReady: true,
    icon: SushiIcon,
    tooltip: 'Based on your SUSHI and xSUSHI balance',
  },
  {
    name: 'Uniswap Reward',
    amount: 0,
    id: 'uniswapGmi',
    isReady: true,
    icon: UnicornIcon,
    tooltip: 'Based on your UNI balance',
  },
];

export const PROMOTIONS_CARDS = [
  {
    name: 'Referral Bonus',
    component: ReferralBonusCard,
    icon: GreenHeartIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
  {
    name: 'Early Degen Bonus',
    component: EarlyDegenCard,
    icon: EarlyDegenIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
  {
    name: 'Aped In Bonus',
    component: ApedCard,
    icon: ApedIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
  {
    name: 'NRG Diamond Hands Bonus',
    component: DiamondBonusCard,
    icon: DiamondIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: false,
  },
  {
    name: 'Spread the Word Bonus',
    component: SpreadTheWordCard,
    icon: SpreadWordIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: false,
  },
  {
    name: 'Tweet Bonus',
    component: TweetBonusCard,
    icon: BirdIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
];

export const DIAMOND_LEVELS = {
  maxLevel: 6,
  maxNRG: 250_000,
  upToBonus: 100,
  0: {
    percentage: 20,
    limit: 200,
  },
  1: {
    percentage: 30,
    limit: 1000,
  },
  2: {
    percentage: 40,
    limit: 5000,
  },
  3: {
    percentage: 50,
    limit: 25000,
  },
  4: {
    percentage: 69,
    limit: 100000,
  },
  5: {
    percentage: 100,
    limit: 250000,
  },
};

export const APED_LEVELS = {
  maxLevel: 7,
  0: {
    percentage: 10,
  },
  1: {
    percentage: 12,
  },
  2: {
    percentage: 15,
  },
  3: {
    percentage: 20,
  },
  4: {
    percentage: 30,
  },
  5: {
    percentage: 40,
  },
  6: {
    percentage: 50,
  },
};

export const EARLY_DEGEN_LEVELS = {
  maxLevel: 4,
  0: {
    percentage: 10,
    tier: 4,
  },
  1: {
    percentage: 20,
    tier: 3,
  },
  2: {
    percentage: 25,
    tier: 2,
  },
  3: {
    percentage: 30,
    tier: 1,
  },
};

export const getEarlyDegenLevel = (percentage) => {
  for (const [key, value] of Object.entries(EARLY_DEGEN_LEVELS)) {
    if (value.percentage === percentage) {
      return parseInt(key, 10);
    }
  }
  return EARLY_DEGEN_LEVELS.maxLevel;
};

export const SPREAD_WORD_LEVELS = {
  maxLevel: 5,
  upToBonus: 50,
  0: {
    percentage: 0,
    referrals: 0,
  },
  1: {
    percentage: 10,
    referrals: 1,
  },
  2: {
    percentage: 20,
    referrals: 3,
  },
  3: {
    percentage: 30,
    referrals: 10,
  },
  4: {
    percentage: 40,
    referrals: 20,
  },
  5: {
    percentage: 50,
    referrals: 100,
  },
};

export const REFERRAL_PROGRAM_WEEKLY_BONUS = 20; // %

export const WEEKLY_RP = [
  {
    week: 1,
    start: SEASONS[CURRENT_SEASON].start,
    end: 1726272000000, // September 14, 2024 12:00:00 AM
  },
  {
    week: 2,
    start: 1726272000001,
    end: 1726876800000, // September 21, 2024 12:00:00 AM
  },
  {
    week: 3,
    start: 1726876800001,
    end: 1727481600000, // September 28, 2024 12:00:00 AM
  },
  {
    week: 4,
    start: 1727481600001,
    end: SEASONS[CURRENT_SEASON].end,
  },
];

export const hasWeeklyRPStarted = (week) => {
  const currentTimestamp = Date.now();
  const weekData = WEEKLY_RP.find((rp) => rp.week === week);
  return weekData ? currentTimestamp >= weekData.start : false;
};

export const getCurrentWeek = () => {
  const currentTimestamp = Date.now();
  const currentWeek = WEEKLY_RP.find(
    (week) => currentTimestamp >= week.start && currentTimestamp <= week.end,
  );
  return currentWeek ? currentWeek.week : 0;
};

export const getDaysUntilNextWeek = () => {
  const currentTimestamp = Date.now();
  const currentWeekIndex = WEEKLY_RP.findIndex(
    (week) => currentTimestamp >= week.start && currentTimestamp <= week.end,
  );

  if (currentWeekIndex === -1) {
    return null;
  }

  const nextWeek = WEEKLY_RP[currentWeekIndex + 1];
  if (!nextWeek) {
    return null;
  }

  const millisecondsUntilNextWeek = nextWeek.start - currentTimestamp;
  const daysUntilNextWeek = millisecondsUntilNextWeek / (1000 * 60 * 60 * 24);

  return Math.ceil(daysUntilNextWeek);
};
