import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga4';

import 'styles/index.css';

import App from 'pages/App';

if (process.env.REACT_APP_GOOGLE_TRACKING_ID !== '') {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
);
