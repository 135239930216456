import { forwardRef, useEffect, useRef } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  styled,
} from '@mui/material';

import referral from 'services/referral';

import { SOCIAL_LINKS } from 'constants/index';
import { openLink } from 'utils/openLink';
import { capitalizeAll } from 'utils/helper';

import { BonusDescription, CongratulationsModal } from 'components/PromotionCard';
import Modal from 'components/Modal';
import { GrayButtonOutlined, StandardButton } from 'components/Button';
import { GreenSpanText, Text } from 'components/Text';
import { FlexBox, FlexCenter, FlexCol } from 'components/StyledComponents';
import TextInput from 'components/TextInput';
import {
  ApedIcon,
  ChevronIcon,
  DiscordIcon_2,
  GreenCheckBox,
  TelegramIcon,
  XLogo,
} from 'components/ImageComponent';

/********************  Styled Components  ********************/
const Container = styled('div')(({ padding }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: padding,
}));

const StyledAccordion = styled(Accordion)(() => ({
  '&.MuiAccordion-root': {
    margin: 0,
    boxShadow: 'none',
    width: '100%',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.background.filter,
  padding: 0,
  height: 72,
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 24,
}));

const titleIconStyle = {
  width: 32,
  height: 32,
  mr: '11px',
  fill: (theme) => theme.palette.icon.secondary,
};

const buttonIconStyle = {
  mr: 1,
  width: 24,
  height: 24,
  '& path': {
    fill: 'white',
  },
};

/********************  Helpers  ********************/
const validateInput = (input) => input.length && !input.startsWith('@');
const validateDiscord = (input) => /^[a-zA-Z0-9._]{2,32}$/.test(input);

/********************  Sub Component  ********************/
// eslint-disable-next-line react/display-name
const SocialMediaAccordion = forwardRef(
  ({ type, isOpen, handleOpen, Icon, title, button, input }, ref) => {
    const [isExpanded, setIsExpanded] = useStateIfMounted(isOpen);
    const [inputText, setInputText] = useStateIfMounted('');
    const [error, setError] = useStateIfMounted({ status: false, message: '' });
    const [done, setDone] = useStateIfMounted(false);

    const handleChange = (e) => {
      setInputText(e.target.value);
      if (type === 'discord') {
        if (validateDiscord(e.target.value)) {
          setError({ status: false, message: '' });
        } else {
          setError({ status: true, message: 'Invalid Discord username' });
        }
        return;
      }
      if (validateInput(e.target.value)) {
        setError({ status: true, message: 'Text field should start with "@"' });
      } else {
        setError({ status: false, message: '' });
      }
    };

    const handleComplete = () => {
      button.onClick();
      setTimeout(() => {
        setDone(true);
      }, 1337);
      setIsExpanded(false);
    };

    return (
      <StyledAccordion expanded={isExpanded}>
        <StyledAccordionSummary
          onClick={() => {
            if (type === 'twitter') {
              setTimeout(() => {
                setDone(true);
              }, 3000);
            } else {
              !done && setIsExpanded(!isExpanded);
            }
            handleOpen();
          }}
        >
          <FlexBox width="100%" justify="space-between" sx={{ px: 3 }}>
            <FlexCenter>
              <Icon sx={titleIconStyle} color="#FFFFFF" />
              {title}
            </FlexCenter>
            {done ? (
              <GreenCheckBox />
            ) : (
              <GrayButtonOutlined width={type === 'twitter' ? 80 : 40} height={40}>
                {type === 'twitter' ? (
                  'Follow'
                ) : (
                  <ChevronIcon sx={{ rotate: isOpen && '180deg' }} />
                )}
              </GrayButtonOutlined>
            )}
          </FlexBox>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <FlexCol>
            <StandardButton
              width={button.width}
              height={40}
              sx={{
                fontWeight: 600,
                background: button.color,
                '&:hover': {
                  background: button.color,
                },
                '&:disabled': {
                  background: button.color,
                },
              }}
              onClick={handleComplete}
              disabled={
                type === 'discord'
                  ? !validateDiscord(inputText)
                  : !inputText.length || !!validateInput(inputText)
              }
            >
              <Icon sx={buttonIconStyle} />
              {button.text}
            </StandardButton>
          </FlexCol>
          <Text main="true" align="left" fw={600} mt={24} mb={8}>
            Please provide your {input}
          </Text>
          <TextInput
            width="100%"
            height={48}
            value={inputText}
            onChange={handleChange}
            error={error.status}
            helperText={error.message}
            placeholder={capitalizeAll(input)}
            ref={ref}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
    );
  },
);

const TwitterAccordion = ({ type, isOpen, handleOpen, twitterInputRef, profile, link }) => (
  <SocialMediaAccordion
    type={type}
    isOpen={isOpen}
    handleOpen={handleOpen}
    Icon={XLogo}
    title={
      <Text main="true" fs={20} fw={600} lh={28}>
        Follow <GreenSpanText>{profile}</GreenSpanText>
      </Text>
    }
    button={{
      width: 'max-content',
      color: '#1D9BF0',
      text: `Follow ${profile}`,
      onClick: () => openLink(link),
    }}
    input="Twitter handle"
    ref={twitterInputRef}
  />
);

const DiscordAccordion = ({ type, isOpen, handleOpen, discordInputRef, link, update }) => (
  <SocialMediaAccordion
    type={type}
    isOpen={isOpen}
    handleOpen={handleOpen}
    Icon={DiscordIcon_2}
    title={
      <Text main="true" fs={20} fw={600} lh={28}>
        Join our <GreenSpanText>Discord Community</GreenSpanText>
      </Text>
    }
    button={{
      width: 'max-content',
      color: '#5865F2',
      text: `Join Energi`,
      onClick: () => {
        openLink(link);
        update();
      },
    }}
    input="Discord username"
    ref={discordInputRef}
  />
);

const TelegramAccordion = ({ isOpen, handleOpen, telegramInputRef, update }) => (
  <SocialMediaAccordion
    type="telegram"
    isOpen={isOpen}
    handleOpen={handleOpen}
    Icon={TelegramIcon}
    title={
      <Text main="true" fs={20} fw={600} lh={28}>
        Join <GreenSpanText>@GonnaMakeItNFTs</GreenSpanText>
      </Text>
    }
    button={{
      width: 266,
      color: '#2CA5E0',
      text: 'Join @GonnaMakeItNFTs',
      onClick: () => {
        openLink(SOCIAL_LINKS.Telegram);
        update();
      },
    }}
    input="Telegram username"
    ref={telegramInputRef}
  />
);

/********************  Main Component  ********************/
const Expanded = {
  DiscordEnergi: 'Discord',
  TwitterEnergi: 'Twitter-Energi',
  TwitterGMI: 'Twitter-GMI',
  TwitterTommy: 'Twitter-Tommy',
  Telegram: 'Telegram',
};

const Actions = {
  twitterGmi: false,
  twitterEnergi: false,
  twitterTommy: false,
  discord: false,
  telegram: false,
};

const ApedInModal = ({ isOpen, onDismiss, refetchData, percentage }) => {
  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [expanded, setExpanded] = useStateIfMounted(null);
  const [error, setError] = useStateIfMounted({ status: false, message: '' });
  const [socialActions, setSocialActions] = useStateIfMounted(Actions);
  const [completed, setCompleted] = useStateIfMounted(false);

  const handleReserveBonus = () => {
    setIsLoading(true);

    const apedIn = async () => {
      try {
        const response = await referral.activateApedInBonus({
          discord: discordEnergiInputRef.current?.value,
          telegram: telegramInputRef.current?.value,
        });

        if (!response.success) {
          setError({ status: true, message: response.message });
          return;
        }

        setCompleted(true);
      } catch (error) {
        setError({ status: true, message: 'Something went wrong' });
      } finally {
        setIsLoading(false);
      }
    };
    apedIn();
  };

  const handleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  const updateSocialAction = (action, value) => {
    setSocialActions((prevActions) => ({
      ...prevActions,
      [action]: value,
    }));
  };

  const handleCloseModals = () => {
    setSocialActions(Actions);
    setCompleted(false);
    onDismiss();
    completed && refetchData();
  };

  const twitterGMIInputRef = useRef();
  const twitterTommyInputRef = useRef();
  const twitterEnergiInputRef = useRef();
  const discordEnergiInputRef = useRef();
  const telegramInputRef = useRef();

  // remove error message after 3 seconds
  useEffect(() => {
    if (error.status) {
      setTimeout(() => {
        setError({ status: false, message: '' });
      }, 3000);
    }
  }, [error.status]);

  if (completed) {
    return (
      <CongratulationsModal
        isOpen={completed}
        closeModal={handleCloseModals}
        mainTitle="Completed!"
        title="Congratulations! Your bonus is activated."
        bonuses={[{ icon: ApedIcon, name: 'Aped In', amount: percentage }]}
      />
    );
  }

  return (
    <Modal title="Activate your bonus" isOpen={isOpen} onDismiss={handleCloseModals} width={556}>
      <Container padding={24}>
        <Text main="true" align="left" fw={600}>
          Complete all 5 entries to activate your bonus!
        </Text>
      </Container>

      <TwitterAccordion
        isOpen={expanded === Expanded.TwitterGMI}
        handleOpen={() => {
          openLink(SOCIAL_LINKS?.TwitterGMI);
          updateSocialAction('twitterGmi', true);
        }}
        twitterInputRef={twitterGMIInputRef}
        profile="@GonnaMakeItNFTs"
        link={SOCIAL_LINKS?.TwitterGMI}
        type="twitter"
      />
      <TwitterAccordion
        isOpen={expanded === Expanded.TwitterEnergi}
        handleOpen={() => {
          openLink(SOCIAL_LINKS?.TwitterEnergi);
          updateSocialAction('twitterEnergi', true);
        }}
        twitterInputRef={twitterEnergiInputRef}
        profile="@Energi"
        link={SOCIAL_LINKS?.TwitterEnergi}
        type="twitter"
      />
      <TwitterAccordion
        isOpen={expanded === Expanded.TwitterTommy}
        handleOpen={() => {
          openLink(SOCIAL_LINKS?.TwitterTommy);
          updateSocialAction('twitterTommy', true);
        }}
        twitterInputRef={twitterTommyInputRef}
        profile="@TommyWorldPower"
        link={SOCIAL_LINKS?.TwitterTommy}
        type="twitter"
      />
      <DiscordAccordion
        isOpen={expanded === Expanded.DiscordEnergi}
        handleOpen={() => handleExpand(Expanded.DiscordEnergi)}
        discordInputRef={discordEnergiInputRef}
        link={SOCIAL_LINKS?.DiscordEnergi}
        type="discord"
        update={() => updateSocialAction('discord', true)}
      />
      <TelegramAccordion
        isOpen={expanded === Expanded.Telegram}
        handleOpen={() => handleExpand(Expanded.Telegram)}
        telegramInputRef={telegramInputRef}
        type="telegram"
        update={() => updateSocialAction('telegram', true)}
      />

      <div style={{ marginTop: 16, width: '100%' }}>
        <BonusDescription
          bonuses={[{ icon: ApedIcon, name: 'Aped In Bonus', amount: percentage }]}
        />
      </div>

      <Container padding={24}>
        <Text tertiary="true" align="left" fs={14} fw={500} lh={20} mb={16}>
          All entries will be verified. Make sure you stay in the community through the token
          generation event or you will lose your bonus!
        </Text>
        {error.status && (
          <Text error="true" align="left" fs={14} fw={500} lh={20} mb={16}>
            {error.message}
          </Text>
        )}
        <StandardButton
          width="100%"
          height={48}
          onClick={handleReserveBonus}
          disabled={!Object.values(socialActions).every((value) => value === true) || error.status}
        >
          {isLoading ? <CircularProgress color="inherit" sx={{ p: '4px' }} /> : 'Activate Bonus'}
        </StandardButton>
      </Container>
    </Modal>
  );
};

export default ApedInModal;
