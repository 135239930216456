import { Box, styled } from '@mui/material';
import Blockies from 'react-blockies';

import { useWallet } from 'context/WalletContext';
import { useDomains } from 'hooks/useDomains';

const BlockieContainer = styled(Box)(({ size, rest }) => ({
  borderRadius: '50%',
  width: size * 8,
  height: size * 8,
  overflow: 'hidden',
  ...rest,
}));

const Blockie = ({ size = 4, customAddress, ...rest }) => {
  const { address } = useWallet();
  const { ENSavatar } = useDomains(address);

  return (
    <BlockieContainer size={size} {...rest}>
      {ENSavatar ? (
        <img src={ENSavatar} />
      ) : customAddress ? (
        <Blockies scale={size} seed={customAddress} />
      ) : address ? (
        <Blockies scale={size} seed={address} />
      ) : null}
    </BlockieContainer>
  );
};

export default Blockie;
