import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide7 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>Creator Airdrop</ModalHeading>
        <ModalText>
          Earn points by launching and managing NFT collections on the GMI NFT Marketplace. Points
          are allocated based on your collection&apos;s volume:
        </ModalText>
        <br />
        <UnorderedList>
          <li>
            <ModalText>
              <strong>Volume-based Rewards:</strong> Earn 1 point per 1 NRG in volume per day for
              your collections.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Support Creators:</strong> This program rewards creators for their
              contributions, encouraging new and exciting collections.
            </ModalText>
          </li>
        </UnorderedList>
        <br />
        <ModalText>Launch your collection and start earning points today!</ModalText>
      </Box>
      <ImageBox imagewidth={321}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide7;
