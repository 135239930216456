import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadingBar() {
  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: '100px',
        display: 'flex',
        justifyContent: 'center',
        placeItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
