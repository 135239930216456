import React, { useEffect, useRef } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled, useTheme } from '@mui/material/styles';
import { Grow, Paper, MenuList, Popper, MenuItem } from '@mui/material';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';

import { isExternalLink } from 'utils/helper';
import { MenuButton } from './NavLinks';

const MENU_CLOSING_TIME = 100;

/********************  Styled Components  ********************/
const SubMenuItem = styled(MenuItem)(({ theme, height }) => ({
  height: height ?? 56,
  padding: 0,
  color: theme.palette.text.main,
  background: theme.palette.background.secondary,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,

  '&.Mui-selected': {
    background: theme.palette.background.secondary,
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
      boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
      transition: 'box-shadow 0.3s ease-in-out',
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.background.dark,
    transition: 'box-shadow 0.3s ease-in-out',
    zIndex: 10,
  },

  '& > a': {
    width: '100%',
  },
}));

const SubMenuComponent = styled(Popper)(() => ({
  width: '220px',
  margin: '0px',
}));

const SubMenuList = styled(MenuList)(() => ({
  padding: '0px',

  '& li:last-of-type': {
    borderBottom: 'none',
  },
}));

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.text.main,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  textDecoration: 'none',
  width: '100%',
  padding: theme.spacing(2),
}));

const CustomLink = styled(ReactRouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.main,
  width: '100%',
  padding: theme.spacing(2),
}));

/********************  Main Component  ********************/
const SubMenu = ({ index, name, path, menus, active }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useStateIfMounted(null);
  const open = Boolean(anchorEl);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClick = (event) => {
    navigate(path);
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const leaveMenu = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, MENU_CLOSING_TIME);
  };

  return (
    <div onMouseLeave={leaveMenu}>
      <MenuButton
        id={`submenu-button-${index}`}
        ref={anchorRef}
        active={active}
        aria-controls={open ? `sub-menu-${index}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseOver={(event) => setAnchorEl(event.currentTarget)}
        className={`${name}-${theme.palette.mode}`}
      >
        {name}
      </MenuButton>
      <SubMenuComponent
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            sx={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper elevation={2}>
              <SubMenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                sx={{ padding: 0 }}
              >
                {menus.map((menu, index) =>
                  isExternalLink(menu.path) ? (
                    <SubMenuItem key={index}>
                      <Link rel="noopener noreferrer" target="_blank" href={menu.path}>
                        {menu.name}
                      </Link>
                    </SubMenuItem>
                  ) : (
                    <SubMenuItem key={index}>
                      <CustomLink to={menu.path}>{menu.name}</CustomLink>
                    </SubMenuItem>
                  ),
                )}
              </SubMenuList>
            </Paper>
          </Grow>
        )}
      </SubMenuComponent>
    </div>
  );
};

export default SubMenu;
