import { Box, Button, Typography, useMediaQuery, styled } from '@mui/material';

import { ModalText } from '.';

const SeparatorLine = styled('hr')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  border: 'none',
  borderTop: `1px solid ${theme.palette.border.separator}`,
}));

const GoAheadButton = styled(Button)(({ theme }) => ({
  width: 388,
  fontSize: 16,
  fontWeight: 600,
  borderRadius: theme.spacing(1),
  height: 48,
}));

const LaterButton = styled(Button)(({ theme }) => ({
  width: 388,
  fontSize: 16,
  fontWeight: 600,
  borderRadius: theme.spacing(1),
  height: 48,
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.border.decorative}`,
}));

const WelcomeTitle = styled(Typography)(({ theme }) => ({
  fontSize: 42,
  fontWeight: 500,
  marginBottom: theme.spacing(2),

  '@media (max-width: 905px)': {
    fontSize: 28,
  },
  '@media (max-width: 600px)': {
    fontSize: 16,
  },
}));

const Welcome = ({ next, dismiss }) => {
  const below905 = useMediaQuery('(max-width: 905px)');

  return (
    <>
      <WelcomeTitle>Welcome to GMI Airdrop Season 2!</WelcomeTitle>
      <ModalText>
        Brave adventurer, drawn by whispers of beauty and boundless opportunities, you step into the
        wondrous realm of NFTs. Guided by fairness and transparency, GMI safeguards creator
        royalties, ensuring a symbiotic relationship between creators and patrons. Beware of lurking
        darkness, for scammers prey on the unsuspecting. Fear not, for we, guardians of this realm
        stand vigilant, our watchful eyes ever trained to thwart deceit and safeguard the integrity
        of the marketplace.
        <br />
        <br />
        Spread word of this wondrous realm far and wide, for in doing so, you honor the legacy of
        those who dare to dream and create.
      </ModalText>
      <SeparatorLine />
      <Box>
        <Typography fontSize={below905 ? 16 : 32} fontWeight={600} textAlign="center">
          Would you like me to explain the rules?
        </Typography>
        <Box display="flex" justifyContent="center" marginTop={3}>
          <GoAheadButton variant="contained" onClick={next}>
            Go ahead
          </GoAheadButton>
        </Box>
        <Box display="flex" justifyContent="center" marginTop={2}>
          <LaterButton onClick={dismiss} variant="contained">
            Later
          </LaterButton>
        </Box>
      </Box>
    </>
  );
};

export default Welcome;
