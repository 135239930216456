import { createContext, useEffect, useReducer } from 'react';

import { AIRDROP_SEASON } from 'constants';
import referral from 'services/referral';

import { useWallet } from './WalletContext';

const initialState = {
  data: {},
  loading: true,
};

const ACTIONS = {
  SET_DATA: 'SET_DATA',
  SET_LOADING: 'SET_LOADING',
};

const ReferralProgramReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
  }

  throw Error('Unknown action: ' + action.type);
};

export const ReferralProgramContext = createContext(null);

export const ReferralProgramProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReferralProgramReducer, initialState);
  const { address, isConnected, isAuthenticated } = useWallet();

  const fetchReferralData = async () => {
    setLoading(true);
    const data = await Promise.all([
      referral.getReferralDetail(),
      referral.getReferralDetail(AIRDROP_SEASON),
    ]);
    dispatch({ type: ACTIONS.SET_DATA, payload: data });
    setLoading(false);
  };

  const setLoading = (loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: loading });
  };

  useEffect(() => {
    if (isConnected && isAuthenticated) {
      fetchReferralData();
    }
  }, [address, isConnected, isAuthenticated]);

  return (
    <ReferralProgramContext.Provider value={{ state, dispatch }}>
      {children}
    </ReferralProgramContext.Provider>
  );
};
