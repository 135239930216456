import { useContext } from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';

import { DashboardContext } from 'context/DashboardContext';
import { getLevelByXP } from 'constants/bonusS2';

import SingleLevel from './SingleLevel';
import CurrentLevel from './CurrentLevel';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  marginBottom: theme.spacing(3),
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
}));

const GameProgress = () => {
  const above1440 = useMediaQuery('(min-width: 1440px)');
  const above1280 = useMediaQuery('(min-width: 1280px)');
  const above905 = useMediaQuery('(min-width: 905px)');
  const above600 = useMediaQuery('(min-width: 600px)');

  const { state } = useContext(DashboardContext);

  const basePoints = state.data?.basePoints ?? 0;
  const currentLevel = getLevelByXP(basePoints).level;

  return (
    <Container>
      <CurrentLevel />
      {above600 && (
        <>
          <SingleLevel level={currentLevel + 1} />
          <SingleLevel level={currentLevel + 2} />
          {above1440 && <SingleLevel level={currentLevel + 3} />}
          {above905 && !above1280 && (
            <>
              <SingleLevel level={currentLevel + 2} />
              <SingleLevel level={currentLevel + 3} />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default GameProgress;
