import { toast } from 'react-toastify';
import { useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

/********************  Sub Component  ********************/
const RenderMessage = ({ icon, color, ...props }) => {
  const { message, link, subText } = props;
  const Icon = icon;

  return (
    <div style={{ display: 'flex' }}>
      {icon && <Icon style={{ fill: color }} />}
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        {message}
        <br />
        {subText && <p>{subText}</p>}
        {link && (
          <a target="_blank" href={link} rel="noreferrer">
            View on Etherscan
          </a>
        )}
      </div>
      <CloseOutlinedIcon className="close" />
    </div>
  );
};

/********************  Main Hook  ********************/
const useNotification = () => {
  const theme = useTheme();

  const showNotification = ({ type, ...props }) => {
    const color =
      type === 'success' || type === 'successNoIcon'
        ? theme.palette.support.success
        : type === 'error'
        ? theme.palette.support.error
        : type === 'pending' || type === 'info'
        ? theme.palette.support.warning2
        : null;

    switch (type) {
      case 'error':
        return toast(<RenderMessage icon={ErrorOutlineIcon} color={color} {...props} />, {
          progressStyle: { background: color },
        });

      case 'success':
        return toast(<RenderMessage icon={CheckCircleOutlineIcon} color={color} {...props} />, {
          progressStyle: { background: color },
        });

      case 'warning':
        return toast(<RenderMessage icon={WarningAmberOutlinedIcon} color={color} {...props} />, {
          progressStyle: { background: color },
        });

      case 'info':
        return toast(<RenderMessage icon={ErrorOutlineIcon} color={color} {...props} />, {
          progressStyle: { background: color },
        });

      case 'pending':
        return toast(<RenderMessage icon={TimerOutlinedIcon} color={color} {...props} />, {
          progressStyle: { background: color },
        });

      case 'successNoIcon':
        return toast(<RenderMessage color={color} {...props} />, {
          progressStyle: { background: color },
        });

      default:
        return toast(renderMsg);
    }
  };

  return showNotification;
};

export default useNotification;
