import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material';
import { NAV_LINKS, ROUTES } from 'pages/routes';
import SubMenu from './SubMenu';

/********************  Styled Components  ********************/
const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}));

export const MenuButton = styled(NavLink)(({ theme }) => ({
  height: 60,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '24px',
  textDecoration: 'none',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
  padding: '0px 15px',
}));

/********************  Main Component  ********************/
const NavLinks = () => {
  const theme = useTheme();
  const location = useLocation();

  const activeStyle = {
    color: theme.palette.text.active,
    border: `3px solid ${theme.palette.button.active}`,
    height: 40,
  };

  const checkAirdropPages = (link) => {
    return (
      link.path === '/' &&
      (location.pathname.startsWith('/0x') ||
        location.pathname.startsWith(ROUTES.ConfirmEmail) ||
        location.pathname.startsWith(ROUTES.EmailVerified))
    );
  };

  return (
    <Container>
      {NAV_LINKS.map((item, index) =>
        item.submenus ? (
          <SubMenu
            key={index}
            index={index}
            name={item.name}
            path={item.path}
            menus={item.submenus}
          />
        ) : (
          <MenuButton
            key={index}
            to={item.path}
            href={item.path}
            target={item.path.includes('http') ? '_blank' : '_self'}
            rel="noopener noreferrer"
            style={({ isActive }) => (isActive || checkAirdropPages(item) ? activeStyle : null)}
            end
          >
            {item.name}
          </MenuButton>
        ),
      )}
    </Container>
  );
};

export default NavLinks;
