import { ETH_ADDRESS_VALIDATOR } from './validators';

export const formatAddress = (address, numBeforeDots, numAfterDots) => {
  return `${address?.slice(0, numBeforeDots)}...${
    numAfterDots ? address?.slice(-numAfterDots) : ``
  }`;
};

export const isValidAddress = (address) => {
  return ETH_ADDRESS_VALIDATOR.test(address);
};

// Support links with or without http(s)
export const getAddressFromURL = (url) => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
  const match = url.match(regex);

  if (match && match[1]) {
    const path = url.replace(`${match[0]}/`, '');
    return path.split('/').pop();
  }

  return url.split('/').pop();
};
