import React from 'react';
import { styled } from '@mui/material';
import Modal, { Divider } from 'components/Modal';
import { Text } from 'components/Text';
import { GrayButtonOutlined } from 'components/Button';
import { BonusDescription } from 'components/PromotionCard';
import { CongratsFaceIcon } from 'components/ImageComponent';

/********************  Styled Components  ********************/
const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px 24px 16px 24px',
  gap: 16,
}));

const ButtonWrapper = styled('div')(() => ({
  padding: '24px 16px',
  width: '100%',
}));

/********************  Main Component  ********************/
const CongratulationsModal = ({ isOpen, closeModal, title, subTitle, bonuses, mainTitle }) => {
  return (
    <Modal
      title={mainTitle || 'Congratulations'}
      isOpen={isOpen}
      onDismiss={closeModal}
      width={524}
    >
      <Container>
        <CongratsFaceIcon />
        <Text main="true" fs={32} fw={600} lh={40}>
          {title}
        </Text>
        <Text tertiary="true" fw={600} sx={{ maxWidth: 444 }}>
          {subTitle}
        </Text>
      </Container>

      <Divider sx={{ mb: 2 }} />

      <BonusDescription bonuses={bonuses} completed />

      <ButtonWrapper>
        <GrayButtonOutlined width="100%" height={48} onClick={closeModal}>
          Back to Airdrop Dashboard
        </GrayButtonOutlined>
      </ButtonWrapper>
    </Modal>
  );
};

export default CongratulationsModal;
