import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide10 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>Points to GMI</ModalHeading>
        <ModalText>
          All points earned will convert to GMI tokens at the Token Generation Event (TGE).
        </ModalText>
        <br />
        <UnorderedList>
          <li>
            <ModalText>
              <strong>Keep Track:</strong> Monitor your points on you airdrop dashboard.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Stay Updated:</strong> Log in regularly for the latest updates and progress.
            </ModalText>
          </li>
        </UnorderedList>
        <br />
        <ModalText>Ensure you maximize your points for a greater reward at TGE!</ModalText>
      </Box>
      <ImageBox imagewidth={233}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide10;
