import { useStateIfMounted } from 'use-state-if-mounted';
import { InfoIcon } from 'components/ImageComponent';
import { FlexCenter, ToolTip } from 'components/StyledComponents';

const TooltipInfoIcon = ({ text, handleTooltip }) => {
  const [open, setOpen] = useStateIfMounted(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ToolTip
      title={text.toString()}
      placement="top"
      open={open}
      onClose={handleTooltip || handleTooltipClose}
    >
      <FlexCenter>
        <InfoIcon sx={{ cursor: 'pointer' }} onClick={handleTooltip || handleTooltipOpen} />
      </FlexCenter>
    </ToolTip>
  );
};

export default TooltipInfoIcon;
