import { createContext, useReducer } from 'react';

const initialState = {
  onboardingModal: false,
};

const ACTIONS = {
  SET_ONBOARDING_MODAL: 'SET_ONBOARDING_MODAL',
};

const AppReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_ONBOARDING_MODAL: {
      return {
        ...state,
        onboardingModal: action.payload,
      };
    }
  }

  throw Error('Unknown action: ' + action.type);
};

export const AppContext = createContext(null);

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setOnboardingModal = (value) => {
    dispatch({
      type: ACTIONS.SET_ONBOARDING_MODAL,
      payload: value,
    });
  };

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        setOnboardingModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
