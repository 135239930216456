import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide6 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>Continuous.. Earning</ModalHeading>
        <ModalText>
          The Liquidity Airdrop continues until the Token Generation Event (TGE). Your points keep
          accumulating as long as you don&apos;t remove your liquidity.
        </ModalText>
        <br />
        <UnorderedList>
          <li>
            <ModalText>
              <strong>Hold for Bonuses:</strong> Longer liquidity duration means higher multipliers
              and more points.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Maximize Rewards:</strong> Keep your liquidity to maximize rewards until TGE.
            </ModalText>
          </li>
        </UnorderedList>
      </Box>
      <ImageBox imagewidth={329}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide6;
