import { Box, Typography, styled } from '@mui/material';

import LevelContainerSVG from 'assets/season2/level_background.svg';
import LevelBox from './LevelBox';
import { getLevel } from 'constants/bonusS2';
import { Text } from 'components/Text';

const LevelContainer = styled(Box)(({ theme }) => ({
  width: 108,
  height: 172,
  background: `url(${LevelContainerSVG})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const PointText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

const SingleLevel = ({ level }) => {
  const { points, boost } = getLevel(level);

  return (
    <LevelContainer>
      <LevelBox level={level} size="small" />
      <Box>
        <Text component="span" shadow="true" fs={12} fw={500}>
          Boost: {boost}%
        </Text>
      </Box>
      <Box textAlign="center">
        <PointText>{points}</PointText>
        <PointText>Amethysts</PointText>
      </Box>
    </LevelContainer>
  );
};

export default SingleLevel;
