const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

export const formatNumber = (number, decimals = 2) => {
  return number
    ?.toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .replace('.00', '');
};

export const formatBalance = (number, formatThousand = false) => {
  if (!number) {
    return 0;
  }
  if (number >= BILLION) {
    return formatNumber(number / BILLION) + 'B';
  } else if (number >= MILLION) {
    return formatNumber(number / MILLION) + 'M';
  } else if (formatThousand && number >= THOUSAND) {
    return formatNumber(number / THOUSAND) + 'K';
  } else {
    return formatNumber(number);
  }
};
