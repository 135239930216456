import React from 'react';
import { styled, useTheme, Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

/********************  Styled Components  ********************/
const BootstrapDialog = styled(Dialog)(({ theme, width, borderradius }) => ({
  '& .MuiDialog-paper': {
    width: width ?? 600,
    maxWidth: '100%',
    height: 'auto',
    borderRadius: borderradius ?? 8,
  },
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.background.overlay,
  },

  '@media (max-width: 480px)': {
    '& .MuiDialog-paper': {
      position: 'fixed',
      bottom: 0,
      margin: 0,
      width: '100%',
      maxHeight: '100vh',
    },
  },
}));

const FlexBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.text.main,
  fontWeight: 500,
  fontSize: 24,
  lineHeight: '32px',
  backgroundColor: theme.palette.background.secondary,
  padding: '24px 24px 16px 24px',
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  height: 72,
}));

const Container = styled('div')(({ theme, padding }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: padding ?? 0,
  backgroundColor: theme.palette.background.secondary,

  '@media (max-width: 480px)': {
    width: '100vw',
  },
}));

export const Divider = styled('div')(({ theme }) => ({
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.border.separator,
}));

/********************  Main Component  ********************/
const Modal = ({ title, isOpen, onDismiss, children, width, borderradius, padding }) => {
  const theme = useTheme();

  return (
    <BootstrapDialog onClose={onDismiss} open={isOpen} width={width} borderradius={borderradius}>
      {title && (
        <FlexBox>
          {title}
          <IconButton onClick={onDismiss} sx={{ p: 0 }}>
            <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
          </IconButton>
        </FlexBox>
      )}
      <Container padding={padding}>{children}</Container>
    </BootstrapDialog>
  );
};

export default Modal;
