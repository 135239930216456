import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide5 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>Liquidity Airdrop</ModalHeading>
        <ModalText>Earn points by participating in the Liquidity Airdrop:</ModalText>
        <br />
        <UnorderedList>
          <li>
            <ModalText>
              <strong>Bridge Tokens:</strong> Bridge eligible tokens into the Energi blockchain and
              qualify for the airdrop.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Add Liquidity:</strong> Add bridged tokens to eligible pools and start earning
              points.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Booster Multiplier:</strong> Hold your liquidity for longer duration to earn
              multiplier bonuses.
            </ModalText>
          </li>
        </UnorderedList>
      </Box>
      <ImageBox imagewidth={321}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide5;
