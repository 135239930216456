import { ApiClient } from '../ApiClient';

export class LiquidityApi {
  /**
   * Constructs a new Liquidity Api.
   * @alias module:api/LiquidityApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   *  Get initial data
   */
  async fetchData({ address }) {
    return await this.apiClient.callApi('liquidity/stats/{address}', 'get', { address }, {}, null);
  }

  /**
   *  Fetch liquidity airdrop leaderboard
   */
  async fetchLeaderboard({ page, limit, address }) {
    return await this.apiClient.callApi(
      'liquidity/leaderboard',
      'get',
      {},
      { limit, skip: limit * (page - 1), address },
      null,
    );
  }
}
