import { Box, styled } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide3 = ({ image }) => {
  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>X Airdrop Bonus</ModalHeading>
        <ModalText>Earn points by engaging with GMI Marketplace on X (Twitter):</ModalText>
        <UnorderedList>
          <li>
            <ModalText>
              <strong>Tweet about GMI:</strong> Create appealing and relevant content centered
              around GMI Marketplace and Energi. Tag @gonnamakeitNFTs in your tweets.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Points for Engagement:</strong> Once the GonnaMakeItNFTs account likes your
              tweet, you will earn points for every view it receives throughout the season.
            </ModalText>
          </li>
          <li>
            <ModalText>
              <strong>Content Guidelines:</strong> Ensure your content is concise, engaging, and
              follows our guidelines to maximize your points.
            </ModalText>
          </li>
        </UnorderedList>
      </Box>
      <ImageBox imagewidth={321}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide3;
