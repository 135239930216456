import { useStateIfMounted } from 'use-state-if-mounted';
import { Check as CheckIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import user from 'services/user';

import { ZEALY_URL } from 'constants';
import { openLink } from 'utils/openLink';
import { ZEALY_BONUS } from 'constants/bonusS2';

import Modal from 'components/Modal';
import { GreenLinkText, Text } from 'components/Text';
import { CongratsFaceIcon, ZealyIcon } from 'components/ImageComponent';

const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.main,
  border: `1px solid ${theme.palette.border.decorative}`,
  marginTop: theme.spacing(1.5),
  fontSize: 16,
  fontWeight: 600,
  marginTop: theme.spacing(3),
}));

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  width: '100%',
}));

const ZealyAccordin = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.filter,
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  display: 'flex',
  justifyContent: 'space-between',
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1.5),
  fontSize: 14,
  fontWeight: 500,
  backgroundColor: theme.palette.background.tertiary,
  borderRadius: theme.spacing(1),
  '& input': {
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(1),
  },

  '& fieldset': {
    border: `1px solid ${theme.palette.border.decorative}`,
    borderRadius: theme.spacing(1),
  },
}));

const ZealyHeading = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  marginLeft: theme.spacing(2),

  '@media (max-width: 600px)': {
    fontSize: 14,
  },
}));

const CheckIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.button.primary,
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.border.decorative}`,

  '&:hover': {
    cursor: 'default',
  },
}));

const ActivateButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(1),
}));

const BonusActivatedModal = ({ isOpen, toggle }) => {
  return (
    <Modal title="Zealy Bonus" onDismiss={toggle} isOpen={isOpen} width={524}>
      <Container>
        <CongratsFaceIcon width={70} height={70} />
        <Typography fontSize={32} fontWeight={600} marginTop={1}>
          Congratulations! <br />
          Your bonus is activated
        </Typography>
        <Typography fontSize={32} fontWeight={600} marginTop={3}>
          {ZEALY_BONUS}% Bonus
        </Typography>
        <BackButton fullWidth onClick={toggle}>
          Back to Airdrop Dashboard
        </BackButton>
      </Container>
    </Modal>
  );
};

const ZealyModal = ({ isOpen, toggle, reload }) => {
  const [bonusActivated, setBonusActivated] = useStateIfMounted(false);
  const [joined, setJoined] = useStateIfMounted(false);
  const [validatedUsername, setValidatedUsername] = useStateIfMounted(false);
  const [zealyUsername, setZealyUsername] = useStateIfMounted('');
  const [loading, setLoading] = useStateIfMounted(false);
  const [error, setError] = useStateIfMounted(false);

  const toggleBonusActivated = () => setBonusActivated(!bonusActivated);

  const handleOpenZealy = () => {
    openLink(ZEALY_URL);
    setTimeout(() => {
      setJoined(true);
    }, 2000);
  };

  const handleValidateUsername = async () => {
    if (zealyUsername) {
      setLoading(true);
      const response = await user.checkZealyMembership(zealyUsername);
      if (!response) {
        setError(true);
        setLoading(false);
        return;
      }
      setValidatedUsername(response);
      setJoined(true);
      setLoading(false);
    }
  };

  const handleActivateBonus = () => {
    toggleBonusActivated();
    toggle();
    if (validatedUsername) {
      reload();
    }
  };

  return (
    <>
      <Modal title="Activate your bonus" onDismiss={toggle} isOpen={isOpen} width={556}>
        <Box width="100%">
          <Typography padding={3} fontSize={16} fontWeight={600} color={'red'}>
            You must first link your email address to your Zealy account, then input your email
            address below to activate the bonus.
          </Typography>
          <ZealyAccordin>
            <Box display="flex" alignItems="center">
              <ZealyIcon />
              <ZealyHeading fontSize={20} fontWeight={600} marginLeft={2}>
                Join our{' '}
                <GreenLinkText fs="inherit" fw={600} onClick={handleOpenZealy}>
                  Zealy Energi Crew
                </GreenLinkText>
              </ZealyHeading>
            </Box>
            <Box>
              {joined && (
                <CheckIconButton disableRipple>
                  <CheckIcon />
                </CheckIconButton>
              )}
            </Box>
          </ZealyAccordin>
          <Box>
            {validatedUsername ? (
              <Box padding={(theme) => theme.spacing(2, 3)}>
                <Text fs={16} fw={600} align="left">
                  You will activate:
                </Text>
                <Text mt={16} fs={20} align="center" fw={600}>
                  {ZEALY_BONUS}% BONUS
                </Text>
              </Box>
            ) : (
              <Box padding={3} paddingTop={1.5}>
                <Text fs={16} fw={600} align="left">
                  Please provide your Zealy email address
                </Text>
                <CustomTextField
                  error={error}
                  helperText={error && 'Email address is not found in Energi Crew'}
                  placeholder="Your Zealy email address"
                  onChange={(e) => setZealyUsername(e.target.value)}
                  value={zealyUsername}
                />
                {}
              </Box>
            )}
          </Box>
          <Box padding={3} paddingTop={2}>
            <ActivateButton
              variant="contained"
              fullWidth
              disabled={loading}
              onClick={
                loading ? null : validatedUsername ? handleActivateBonus : handleValidateUsername
              }
            >
              {loading ? (
                <CircularProgress size={28} />
              ) : validatedUsername ? (
                'Activate Bonus'
              ) : (
                'Check Membership'
              )}
            </ActivateButton>
          </Box>
        </Box>
      </Modal>
      <BonusActivatedModal isOpen={bonusActivated} toggle={toggleBonusActivated} />
    </>
  );
};

export default ZealyModal;
