import DefaultIcon from 'assets/gmi/GMI-token-logo.svg';

// set as variable instead of env
const BASE_URL = 'https://nft-cdn.gonnamakeit.com/';

export const getFullMediaUrl = (url, backupUrl) => {
  if (!url) {
    return backupUrl?.startsWith('https') ? backupUrl : DefaultIcon;
  }
  return url.startsWith('https') ? url : `${BASE_URL}${url}`;
};
