import { ReferralApi } from 'clientSDK';

const referralApi = new ReferralApi();

export default {
  getAllReferrals: async () => await referralApi.getAllReferrals(),

  applyPromo: async (body) => await referralApi.applyPromo(body),

  getReferralDetail: async (season = 0) => await referralApi.getReferralDetail(season),

  getAirdropBonuses: async () => await referralApi.getAirdropBonuses(), // S1

  getSpreadTheWordBonus: async () => await referralApi.getSpreadTheWordBonus(),

  getNRGDiamondBonus: async () => await referralApi.getNRGDiamondBonus(),

  activateApedInBonus: async (body) => await referralApi.activateApedInBonus(body),
};
