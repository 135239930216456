import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStateIfMounted } from 'use-state-if-mounted';

import { useWallet } from 'context/WalletContext';
import { ZEALY_URL } from 'constants';
import { ZEALY_BONUS } from 'constants/bonusS2';

import { Text, GreenLinkText } from 'components/Text';
import { ZealyIcon } from 'components/ImageComponent';
import { PromotionCard, FlexBox, Container, BonusAmountText } from 'components/PromotionCard';
import { StandardButton, ConnectButton } from 'components/Button';
import ZealyModal from '../modals/ZealyModal';
import { ActivatedButton } from 'components/StyledComponents';

/********************  Main Component  ********************/
const ZealyCard = ({ completed = false, refetchData }) => {
  const [modalOpen, setModalOpen] = useStateIfMounted(false);
  const { isAuthenticated } = useWallet();

  return (
    <>
      <PromotionCard
        Icon={ZealyIcon}
        title="Zealy Bonus"
        tooltip="Join our Zealy community to lock in rewards"
        completed={completed}
      >
        <Container>
          <FlexBox flex={1}>
            {completed ? (
              <Text>
                You are now part of the{' '}
                <GreenLinkText href={ZEALY_URL}>ZealyEnergiCrew</GreenLinkText>
              </Text>
            ) : (
              <Box>
                <Typography fontSize={12} fontWeight={500} marginBottom={1.5}>
                  Join{' '}
                  <GreenLinkText fs={12} fw={500} href={ZEALY_URL}>
                    ZealyEnergiCrew
                  </GreenLinkText>
                </Typography>
                <Box>
                  <BonusAmountText value={30} />
                </Box>
              </Box>
            )}
          </FlexBox>
          {!isAuthenticated ? (
            <ConnectButton height={40} />
          ) : completed ? (
            <ActivatedButton>{ZEALY_BONUS}% BONUS Activated</ActivatedButton>
          ) : (
            <StandardButton height={40} onClick={() => setModalOpen(!modalOpen)}>
              Activate Bonus
            </StandardButton>
          )}
        </Container>
      </PromotionCard>
      <ZealyModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        completed={completed}
        reload={refetchData}
      />
    </>
  );
};

export default ZealyCard;
