import { Box, Button, Typography, styled } from '@mui/material';

import { CongratsFaceIcon } from 'components/ImageComponent';
import Modal from 'components/Modal';

const BigText = styled(Typography)(({ theme, mt }) => ({
  fontSize: 32,
  fontWeight: 600,
  lineHeight: theme.spacing(5),
  marginTop: theme.spacing(mt ?? 0),
}));

const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.main,
  border: `1px solid ${theme.palette.border.decorative}`,
  marginTop: theme.spacing(1.5),
  fontSize: 16,
  fontWeight: 600,
  marginTop: theme.spacing(3),
}));

const HodlersBonusModal = ({ isOpen, toggle, isEligable }) => {
  return (
    <Modal title="NFT Hodlers Bonus" onDismiss={toggle} isOpen={isOpen} width={524}>
      <Box padding={3} textAlign="center" width="100%">
        {isEligable ? (
          <>
            <CongratsFaceIcon width={70} height={70} />
            <BigText mt={1}>
              Congratulations! <br />
              Your earned:
            </BigText>
            <BigText mt={2}>10,000 PTS</BigText>
          </>
        ) : (
          <>
            <Typography fontSize={74} lineHeight={74}>
              😓
            </Typography>
            <BigText mt={1}>You’re not eligible for NFT Hoddlers Bonus</BigText>
          </>
        )}
        <BackButton fullWidth onClick={toggle}>
          Back to Airdrop Dashboard
        </BackButton>
      </Box>
    </Modal>
  );
};

export default HodlersBonusModal;
