import { Box, Typography, styled } from '@mui/material';

const CardContainer = styled(Box)(({ theme, background }) => ({
  background: background,
  border: `2px solid ${theme.palette.border.decorative}`,
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  minWidth: 268,
}));

const CardHeading = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  backgroundColor: theme.palette.background.filter,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  height: 40,
}));

const Card = ({ height = 450, children, heading, background, styles = {} }) => {
  return (
    <CardContainer height={height} background={background} style={styles}>
      {heading && (
        <CardHeading>
          <Typography fontSize={16} fontWeight={500}>
            {heading}
          </Typography>
        </CardHeading>
      )}
      <Box height="calc(100% - 40px)">{children}</Box>
    </CardContainer>
  );
};

export default Card;
