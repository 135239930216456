import { useContext } from 'react';
import { Box, styled } from '@mui/material';

import { DashboardContext } from 'context/DashboardContext';
import { BONUS_TYPES } from 'constants/bonusS2';
import { capitalize } from 'utils/helper';

import { GroupButton } from 'components/StyledComponents';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const BonusOptions = () => {
  const { state, getBonusesByType, setBonusType } = useContext(DashboardContext);

  return (
    <Container>
      {Object.keys(BONUS_TYPES).map((item) => {
        const bonuses = getBonusesByType(item);

        return (
          <GroupButton
            onClick={() => setBonusType(item)}
            key={item}
            active={state.bonusType === item ? 'true' : ''}
          >
            {capitalize(item)} {item !== 'all' && `(${bonuses.length})`}
          </GroupButton>
        );
      })}
    </Container>
  );
};

export default BonusOptions;
