import React from 'react';
import { styled, IconButton, Box } from '@mui/material';

import { GreenCheckIcon } from 'components/ImageComponent';
import { FlexCenter } from 'components/StyledComponents';
import TooltipInfoIcon from 'components/TooltipInfoIcon';
import { Text } from 'components/Text';

/********************  Styled Components  ********************/
const Card = styled('div')(({ theme, started }) => ({
  display: 'flex',
  minWidth: 328,
  width: '100%',
  height: 192,
  borderRadius: 8,
  border: `1px solid ${theme.palette.border.decorative}`,
  overflow: 'hidden',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.filter,
  position: 'relative',
  opacity: started ? 1 : 0.5,
  pointerEvents: started ? 'auto' : 'none',
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.filter,
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
}));

const CheckIconButton = styled((props) => <IconButton disabled disableRipple {...props} />)(
  ({ theme }) => ({
    height: 32,
    width: 32,
    borderRadius: 100,
    background: '#141619',
    padding: 0,
    border: `2px solid ${theme.palette.border.decorative}`,
    cursor: 'default',
  }),
);

const CardHeader = styled(Box)(({ theme }) => ({
  height: 64,
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.border.decorative}`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const FlexBox = styled('div')(({}) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',

  '& img': {
    verticalAlign: 'middle',
  },
}));

/********************  Main Component  ********************/
export const PromotionCard = ({
  Icon,
  title,
  completed,
  children,
  tooltip,
  handleTooltip,
  started = true,
}) => {
  return (
    <Card started={started}>
      <CardHeader>
        <CheckIconButton>{completed ? <GreenCheckIcon width={40} /> : null}</CheckIconButton>
        <Icon width={24} height={24} />
        <FlexBox>
          <FlexCenter gap={11}>
            <Text fs={16} fw={500} lh={28}>
              {title}
            </Text>
            {tooltip && <TooltipInfoIcon text={tooltip} handleTooltip={handleTooltip} />}
          </FlexCenter>
        </FlexBox>
      </CardHeader>
      <Content>{children}</Content>
    </Card>
  );
};
