import React from 'react';
import { alpha, Box, styled } from '@mui/material';

import { Text } from 'components/Text';
import { FlexBox, FlexCenter } from 'components/StyledComponents';

/********************  Styled Components  ********************/
const BonusWrapper = styled('div')(({ theme, completed }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: alpha(
    completed ? theme.palette.primary.main : theme.palette.support.warning,
    0.08,
  ),
  border: `1px solid ${completed ? theme.palette.primary.main : theme.palette.support.warning}`,
  borderStyle: 'dashed',
  borderRadius: theme.spacing(1),
  padding: '14px 24px',
  justifyContent: 'space-between',
}));

/********************  Sub Component  ********************/
const BonusInfo = ({ Icon, name, amount, completed }) => (
  <BonusWrapper completed={completed}>
    <FlexCenter gap={11}>
      <Icon />
      <Text fs={20} fw={600} lh={28}>
        {name}
      </Text>
    </FlexCenter>
    <Text fs={20} fw={600} lh={28}>
      {amount}% BONUS
    </Text>
  </BonusWrapper>
);

/********************  Main Component  ********************/
const BonusDescription = ({ bonuses, completed }) => {
  return (
    <Box width="100%" sx={{ p: '0 24px' }}>
      <FlexBox>
        <Text fw={600} align="left" sx={{ pb: 2 }}>
          {completed ? 'You activated:' : 'You will activate:'}
        </Text>
      </FlexBox>
      {bonuses.map((bonus, idx) => (
        <BonusInfo
          key={idx}
          Icon={bonus.icon}
          name={bonus.name}
          amount={bonus.amount}
          completed={completed}
        />
      ))}
    </Box>
  );
};

export default BonusDescription;
