import React from 'react';
import { styled, useMediaQuery } from '@mui/material';
import { useStateIfMounted } from 'use-state-if-mounted';
import { LevelProgressDiamond, LevelProgressDot as GrayDot } from 'components/StyledComponents';

/********************  Styled Components  ********************/
const Flex = styled('div')(() => ({
  display: 'flex',
  gap: 4,
  cursor: 'default',
  alignItems: 'center',
}));

/********************  Sub Components  ********************/
const YellowDot = ({ clicked }) => <GrayDot yellow clicked={clicked} />;

const GreenDot = ({ clicked }) => <GrayDot green clicked={clicked} />;

const WhiteDot = () => <GrayDot white />;

const MoneyFace = ({ clicked }) => <GrayDot money clicked={clicked} />;

const Diamond = ({ clicked }) => <LevelProgressDiamond clicked={clicked} />;

const DiamondFill = ({ clicked }) => <LevelProgressDiamond diamondContainer clicked={clicked} />;

const RenderDots = (props) => {
  const { clicked, hovered, aboveCurrentLevel, completed, diamond, money } = props;
  if (diamond) {
    return <RenderDiamonds {...props} />;
  }

  if (money) {
    return <RenderMoney {...props} />;
  }

  if (completed) {
    return aboveCurrentLevel ? <GrayDot clicked={clicked} /> : <GreenDot clicked={clicked} />;
  }

  if (aboveCurrentLevel) {
    return hovered ? <WhiteDot /> : <GrayDot clicked={clicked} />;
  }

  return <YellowDot clicked={clicked} />;
};

const RenderMoney = ({ clicked, hovered, aboveCurrentLevel, completed }) => {
  if (completed) {
    return aboveCurrentLevel ? <GrayDot clicked={clicked} /> : <MoneyFace clicked={clicked} />;
  }

  if (aboveCurrentLevel) {
    return hovered ? <MoneyFace clicked={clicked} /> : <GrayDot clicked={clicked} />;
  }

  return <MoneyFace clicked={clicked} />;
};

const RenderDiamonds = ({ clicked, hovered, aboveCurrentLevel, completed }) => {
  if (completed) {
    return aboveCurrentLevel ? <DiamondFill clicked={clicked} /> : <Diamond clicked={clicked} />;
  }

  if (aboveCurrentLevel) {
    return hovered ? <Diamond clicked={clicked} /> : <DiamondFill clicked={clicked} />;
  }

  return <Diamond clicked={clicked} />;
};

/********************  Main Component  ********************/
const IconProgress = ({
  currentLevel,
  maxLevel,
  completed,
  onClickOrHover,
  onHoverOut,
  hoverOnCompleted,
  onLoseFocus,
  money,
  diamond,
  table,
}) => {
  const below600 = useMediaQuery('(max-width:600px)');

  const [clicked, setClicked] = useStateIfMounted(null);
  const [hovered, setHovered] = useStateIfMounted(null);

  const isAboveCurrentLevel = (index) => index + 1 > currentLevel;

  const onClickMobile = (index) => {
    if (below600) {
      setClicked(index);
      onClickOrHover(index);
    }
  };

  const onMouseEnter = (index) => {
    if (completed || (!below600 && !isAboveCurrentLevel(index))) {
      return hoverOnCompleted(index);
    }
    if (!below600) {
      setHovered(index);
      onClickOrHover(index);
    }
  };

  const onMouseLeave = () => {
    if (!below600) {
      setHovered(false);
      onHoverOut();
    }
  };

  const onLoseClickFocus = () => {
    onLoseFocus();
    setClicked(null);
  };

  return (
    <Flex onBlur={onLoseClickFocus} tabIndex={0} table={table}>
      {Array(maxLevel)
        .fill(null)
        .map((_, index) => (
          <Flex
            key={index}
            onClick={() => onClickMobile(index)}
            onMouseEnter={() => onMouseEnter(index)}
            onMouseLeave={onMouseLeave}
          >
            <RenderDots
              clicked={clicked === index}
              hovered={hovered === index}
              aboveCurrentLevel={isAboveCurrentLevel(index)}
              completed={completed}
              money={money}
              diamond={diamond}
            />
          </Flex>
        ))}
    </Flex>
  );
};

export default IconProgress;
