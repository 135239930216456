import React from 'react';

const Landing = React.lazy(() => import('./Landing'));
const ConfirmEmail = React.lazy(() => import('./ConfirmEmail'));
const ReferralProgram = React.lazy(() => import('./ReferralProgram'));
const S1Dashboard = React.lazy(() => import('./S1/Dashboard'));
const S1Leaderboard = React.lazy(() => import('./S1/Leaderboard'));
const Leaderboard = React.lazy(() => import('./Leaderboard'));
const XDashboard = React.lazy(() => import('./XDashboard'));
const LiquidityAirdrop = React.lazy(() => import('./LiquidityAirdrop'));
const CreatorAirdrop = React.lazy(() => import('./CreatorAirdrop'));
const TraderAirdrop = React.lazy(() => import('./TraderAirdrop'));
const Dashboard = React.lazy(() => import('./Dashboard'));
const Boosters = React.lazy(() => import('./Boosters'));
const TokenHodler = React.lazy(() => import('./TokenHodler'));
const NftHodler = React.lazy(() => import('./NftHodler'));
const ZealyAirdrop = React.lazy(() => import('./ZealyAirdrop'));
const NftVolumeAirdrop = React.lazy(() => import('./NftVolumeAirdrop'));
const NftBridgeAirdrop = React.lazy(() => import('./NftBridgeAirdrop'));
const NftPortfolioAirdrop = React.lazy(() => import('./NftPortfolioAirdrop'));
const NftListingAirdrop = React.lazy(() => import('./NftListingAirdrop'));
const S2Dashboard = React.lazy(() => import('./S2'));

export const ROUTES = {
  Landing: '/',
  ConfirmEmail: '/confirm-email',
  EmailVerified: '/email-verified',
  Referral: '/referral',
  S1Dashboard: '/s1',
  S1Leaderboard: '/s1/leaderboard',
  Dashboard: '/s3/dashboard',
  Leaderboard: '/s3/leaderboard',
  XDashboard: '/s3/x-airdrop',
  LiquidityAirdrop: '/s3/liquidity-airdrop',
  CreatorAirdrop: '/s3/creator-airdrop',
  TraderAirdrop: '/s3/trader-airdrop',
  TokenHodler: '/s3/token-hodler',
  NftHodler: '/s3/nft-hodler',
  Boosters: '/s3/boosters',
  ZealyAirdrop: '/s3/zealy-airdrop',
  NftVolumeAirdrop: '/s3/nft-volume',
  NftBridgeAirdrop: '/s3/nft-bridge-airdrop',
  NftPortfolioAirdrop: '/s3/nft-portfolio-airdrop',
  NftListingAirdrop: '/s3/nft-listing-airdrop',
  S2Dashboard: '/s2',
};

export const PAGES = [
  {
    path: '/*',
    element: <Landing />,
  },
  {
    path: ROUTES.Dashboard,
    element: <Dashboard />,
  },
  {
    path: ROUTES.S1Dashboard,
    element: <S1Dashboard />,
  },
  {
    path: ROUTES.S1Leaderboard,
    element: <S1Leaderboard />,
  },
  {
    path: ROUTES.Referral,
    element: <ReferralProgram />,
  },
  {
    path: ROUTES.Leaderboard,
    element: <Leaderboard />,
  },
  {
    path: ROUTES.ConfirmEmail,
    element: <ConfirmEmail />,
  },
  {
    path: ROUTES.XDashboard,
    element: <XDashboard />,
  },
  {
    path: ROUTES.LiquidityAirdrop,
    element: <LiquidityAirdrop />,
  },
  {
    path: ROUTES.CreatorAirdrop,
    element: <CreatorAirdrop />,
  },
  {
    path: ROUTES.TraderAirdrop,
    element: <TraderAirdrop />,
  },
  {
    path: ROUTES.Boosters,
    element: <Boosters />,
  },
  {
    path: ROUTES.TokenHodler,
    element: <TokenHodler />,
  },
  {
    path: ROUTES.NftHodler,
    element: <NftHodler />,
  },
  {
    path: ROUTES.ZealyAirdrop,
    element: <ZealyAirdrop />,
  },
  {
    path: ROUTES.NftVolumeAirdrop,
    element: <NftVolumeAirdrop />,
  },
  {
    path: ROUTES.NftBridgeAirdrop,
    element: <NftBridgeAirdrop />,
  },
  {
    path: ROUTES.NftPortfolioAirdrop,
    element: <NftPortfolioAirdrop />,
  },
  {
    path: ROUTES.NftListingAirdrop,
    element: <NftListingAirdrop />,
  },
  {
    path: ROUTES.S2Dashboard,
    element: <S2Dashboard />,
  },
];

export const NAV_LINKS = [
  {
    name: 'Airdrop Dashboard',
    path: ROUTES.Dashboard,
  },
  {
    name: 'Referral Program',
    path: ROUTES.Referral,
  },
  {
    name: 'Leaderboards',
    path: ROUTES.Leaderboard,
  },
  {
    name: 'GMI Marketplace',
    path: 'https://app.gonnamakeit.com',
  },
  {
    name: 'Resources',
    path: '',
    submenus: [
      {
        name: 'Docs',
        path: 'https://docs.gonnamakeit.com/docs/airdrop/gmi-airdrop-season-3',
      },
      {
        name: 'Airdrop S2',
        path: ROUTES.S2Dashboard,
      },
      {
        name: 'Airdrop S1',
        path: ROUTES.S1Dashboard,
      },
    ],
  },
];
