export const isExternalLink = (path) => {
  if (!path) {
    return false;
  }
  return path.startsWith('https://') || path.startsWith('http://');
};

export const capitalize = (text) => text[0].toUpperCase() + text.slice(1);

export const capitalizeAll = (text) =>
  text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
