import React, { useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Box, Button, CircularProgress, styled, TextField } from '@mui/material';

import referral from 'services/referral';
import { ReferralProgramContext } from 'context/ReferralProgramContext';

import { isValidAddress } from 'utils/address';
import { openLink } from 'utils/openLink';

import Modal, { Divider } from 'components/Modal';
import { GreenSpanText, Text } from 'components/Text';
import { BonusDescription } from 'components/PromotionCard';
import CongratulationsModal from './CongratulationsModal';
import { GreenHeartIcon } from 'components/ImageComponent';

/********************  Styled Components  ********************/
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 2, 3),
  gap: theme.spacing(2),
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
  width: '100%',
}));

const TextFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
  padding: theme.spacing(1, 2, 2, 2),
}));

const LabelContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  fontSize: 14,
  fontWeight: 500,
  backgroundColor: theme.palette.background.tertiary,
  borderRadius: theme.spacing(1),
  '& input': {
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(1),
  },

  '& fieldset': {
    border: `1px solid ${theme.palette.border.decorative}`,
    borderRadius: theme.spacing(1),
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(1),
  width: '100%',
  borderRadius: theme.spacing(1),
}));

/********************  Main Component  ********************/
const ReferralLinkModal = ({ isOpen, closeModal, refetchData }) => {
  const [referralLink, setReferralLink] = useStateIfMounted(null);
  const [completed, setCompleted] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [error, setError] = useStateIfMounted(false);

  const { state } = useContext(ReferralProgramContext);

  const referralAddress = state?.data[0]?.referredBy ?? localStorage.getItem('refcode');
  const placeholder = referralAddress ? referralAddress : 'Enter your referral link here';
  const disabled = !Boolean(referralAddress) && !Boolean(referralLink);

  const handleChange = (e) => {
    setError(false);
    setReferralLink(e.target.value);
  };

  const onApplyPromo = () => {
    setLoading(true);
    let address = referralAddress;
    if (!referralAddress) {
      address = referralLink.startsWith('0x')
        ? isValidAddress(referralLink)
          ? referralLink
          : null
        : referralLink.startsWith('http')
        ? (() => {
            const code = new URL(referralLink).pathname.split('/').pop();
            return code.startsWith('0x') ? (isValidAddress(code) ? code : null) : code;
          })()
        : referralLink;
    }
    if (!address) {
      setError('Invalid referral link');
      setLoading(false);
      return;
    }

    const applyReferralBonus = async () => {
      try {
        const response = await referral.applyPromo({ referralAddress: address });
        if (response.success === true) {
          setCompleted(true);
          return;
        }
        setError(response.message);
      } catch (error) {
        setError('Something went wrong, please contact support');
      } finally {
        setLoading(false);
      }
    };
    applyReferralBonus();
  };

  const closeAllModal = () => {
    closeModal();
    setReferralLink(null);
    setLoading(false);
    setError(false);
    completed && refetchData();
  };

  if (completed) {
    return (
      <CongratulationsModal
        isOpen={completed}
        closeModal={closeAllModal}
        title="Referral Link confirmed succesfully"
        subTitle="Congratulations! You will also get 10% Rebate on GMI Marketplace listings."
        bonuses={[{ icon: GreenHeartIcon, name: 'Referral Bonus', amount: 30 }]}
      />
    );
  }

  return (
    <Modal title="Get Referral Bonus" isOpen={isOpen} onDismiss={closeAllModal} width={524}>
      <Container>
        <Text main="true" fs={32} fw={600} lh={40}>
          Confirm your Referral Link
        </Text>
        <Text tertiary="true" fw={600}>
          You are about to confirm your referral link to activate 30% bonus. Your referral link
          can’t be changed after <br /> confirmation.
        </Text>
      </Container>

      <TextFieldContainer>
        <LabelContainer>
          <Text fs={16} fw={600}>
            Referral Link
          </Text>
          {error && (
            <Text fs={12} fw={400} error="true">
              {error}
            </Text>
          )}
        </LabelContainer>
        <CustomTextField
          placeholder={placeholder}
          disabled={Boolean(referralAddress)}
          error={Boolean(error)}
          onChange={(e) => handleChange(e)}
        />
        <Text fs={12} fw={500} lh="16px">
          *You will also receive 10% Rebate on listings listed directly on the GonnaMakeIt
          marketplace. Learn more about{' '}
          <GreenSpanText
            onClick={() =>
              openLink(
                'https://docs.gonnamakeit.com/docs/about/gmi-airdrop-season-2#2115--gmi-airdrop-referral-bonus',
              )
            }
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Referral Program
          </GreenSpanText>
        </Text>
      </TextFieldContainer>

      <Divider sx={{ mb: 1, mt: 2 }} />

      <BonusDescription bonuses={[{ icon: GreenHeartIcon, name: 'Referral Bonus', amount: 30 }]} />
      <ButtonWrapper>
        <ConfirmButton
          variant="contained"
          onClick={onApplyPromo}
          disabled={loading || Boolean(error) || disabled}
        >
          {loading ? <CircularProgress size={28} /> : 'Confirm'}
        </ConfirmButton>
      </ButtonWrapper>
    </Modal>
  );
};

export default ReferralLinkModal;
