import { EmailApi } from 'clientSDK';

const emailApi = new EmailApi();

export default {
  reserveEmail: async (body) => await emailApi.reserveEmail(body),

  verifyEmail: async (token) => await emailApi.verifyEmail(token),

  resendEmail: async (body) => await emailApi.resendEmail(body),
};
