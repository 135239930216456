import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled, useTheme, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/********************  Styled Components  ********************/
const StyledToastContainer = styled(ToastContainer)(({ colorTheme, colorbottom }) => ({
  width: 440,

  '@media(max-width: 700px)': {
    width: 490,
  },

  '@media(max-width: 600px)': {
    width: 352,
    margin: 14,
  },

  '@media(max-width: 500px)': {
    width: 328,
    margin: 14,
  },

  '&.Toastify__toast-container--top-center': {
    left: 'calc(50% - 14px)',
    transform: 'translateX(-50%)',
  },

  '& .Toastify__toast': {
    background: colorTheme.palette.background.default,
    border: `1px solid ${colorTheme.palette.border.decorative}`,
    borderRadius: 8,
  },

  '& .Toastify__toast-body': {
    color: colorTheme.palette.text.main,
    fontSize: '16px',
    fontWeight: 600,

    '& svg': {
      color: colorbottom,
      margin: 'auto 0',
    },

    '& a, p': {
      textDecoration: 'none',
      color: colorTheme.palette.text.secondary,
      fontWeight: 400,
      fontSize: '14px',
      margin: 0,
    },

    '& .close': {
      color: colorTheme.palette.text.secondary,
      margin: '0 auto',
      marginRight: 0,
      height: 20,
      width: 20,
    },
  },

  '& .Toastify__progress-bar': {
    background: colorbottom,
    height: 5,
  },

  '& .Toastify__close-button': {
    color: colorTheme.palette.text.main,
    opacity: 1,
  },
}));

/********************  Main Component  ********************/
const ToastNotification = () => {
  const [position, setPosition] = useStateIfMounted('bottom-right');

  const theme = useTheme();
  const below500 = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    if (below500) {
      setPosition('top-center');
    } else {
      setPosition('bottom-right');
    }
  }, [below500]);

  return (
    <StyledToastContainer
      colorTheme={theme}
      position={position}
      autoClose={1337}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      closeButton={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default ToastNotification;
