import { Box, Typography, styled } from '@mui/material';

import Modal from 'components/Modal';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'left',
  width: '100%',
}));

const InfoModal = ({ onClose }) => {
  return (
    <Modal title="How to Early Degen?" isOpen={true} onDismiss={onClose} width={524}>
      <Container>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          To qualify for the Early Degen Bonus you need to sign up for the Energi Newsletter. The
          earlier you sign up the higher your bonus.
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={2}>
          An example, if you sign up in the 1st week of airdrop, you will receive an additional 30%
          airdrop bonus on top of all your base points.
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={2}>
          Joining Week
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          Bonus
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          1st week - 30% Bonus
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          2nd week - 25% Bonus
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          3rd week - 20% Bonus
        </Typography>
        <Typography fontSize={14} fontWeight={400} marginTop={0}>
          4th week - 10% Bonus
        </Typography>
      </Container>
    </Modal>
  );
};

export default InfoModal;
