import { Box, styled, Typography, useMediaQuery } from '@mui/material';

import { ImageBox, ModalHeading, ModalText, ResponsiveBox } from '.';

const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingLeft: theme.spacing(3),
}));

const Ruler = styled('span')(() => ({
  height: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

const Slide2 = ({ image }) => {
  const below905 = useMediaQuery('(max-width: 905px)');

  return (
    <ResponsiveBox>
      <Box flex={1}>
        <ModalHeading>GMI Experience Points</ModalHeading>
        <ModalText>
          You can earn points by participating in multiple airdrop programs. These points will
          convert to GMI tokens at the Token Generation Event (TGE).
        </ModalText>
        <Typography fontSize={below905 ? 24 : 32} fontWeight={600} marginTop={3}>
          Airdrop Programs
        </Typography>
        <UnorderedList>
          <li>X Airdrop</li>
          <li>Liquidity Airdrop</li>
          <li>Creator Airdrop</li>
          <li>Trader Airdrop</li>
          <li>GMI Referral Program</li>
        </UnorderedList>
      </Box>
      <ImageBox imagewidth={370}>
        <Ruler />
        <img src={image} style={{ verticalAlign: 'middle' }} />
      </ImageBox>
    </ResponsiveBox>
  );
};

export default Slide2;
