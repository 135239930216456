import { useContext } from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';

import { DashboardContext } from 'context/DashboardContext';

import ProgressBar from './ProgressBar';
import { Text } from 'components/Text';
import LevelBox from './LevelBox';
import { getLevelByXP, getNextLevelXP, getBoostbyLevel } from 'constants/bonusS2';

const CurrentLevelContainer = styled(Box)(({ theme }) => ({
  minWidth: 305,
  flex: 1,
  height: 175,
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 58.84%)',
  borderRadius: theme.spacing(3),
  position: 'relative',
  padding: theme.spacing(1.5, 2),
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: theme.spacing(3),
    padding: 2,
    background: 'linear-gradient(to top,rgba(255, 255, 255, 0.20),transparent, transparent)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

const LevelProgressContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 106,
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 50%, rgba(255, 255, 255, 0.15) 100%)',
  borderRadius: theme.spacing(2),
  position: 'relative',
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: theme.spacing(2),
    padding: 2,
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.20), transparent, transparent)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
  '@media (max-width: 600px)': {
    justifyContent: 'space-between',
    borderRadius: theme.spacing(3),
    '&::before': {
      borderRadius: theme.spacing(3),
    },
  },
}));

const CurrentLevel = () => {
  const above600 = useMediaQuery('(min-width: 600px)');
  const above905 = useMediaQuery('(min-width: 905px)');

  const { state } = useContext(DashboardContext);

  const basePoints = state.data?.basePoints ?? 0;
  const currentLevel = getLevelByXP(basePoints).level;
  const nextLevelPoints = getNextLevelXP(currentLevel);
  const pointsToNextLevel = nextLevelPoints - basePoints;
  const nextLevelBoost = getBoostbyLevel(currentLevel + 1);

  return (
    <CurrentLevelContainer>
      <LevelProgressContainer>
        {above600 ? (
          <>
            <LevelBox size="large" level={currentLevel} />
            <Box marginLeft={1.5}>
              <Text fs={above905 ? 28 : 24} fw={500} component="span" shadow="true">
                Level {currentLevel}
              </Text>
              <Text fs={14} fw={500} color="text.secondary">
                {pointsToNextLevel} Amethysts to next level
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box display="flex" alignItems="center">
                <LevelBox size="small" level={currentLevel} />
                <Text component="span" ml={1} fs={14} fw={500} shadow="true">
                  Level {currentLevel}
                </Text>
              </Box>
              <Text fs={12} fw={500} color="text.secondary" mt={1}>
                {pointsToNextLevel} Amethysts to next level
              </Text>
            </Box>
            <Box>
              <Box>
                <Text fs={12} fw={500} color="text.secondary" textAlign="center">
                  Level {currentLevel + 1} <br />
                </Text>
                <Text fs={12} fw={500} component="span" shadow="true">
                  Boost: {nextLevelBoost}%
                </Text>
                <br />
                <Text fs={12} fw={500} color="text.secondary" component="span">
                  {nextLevelPoints} Amethysts
                </Text>
              </Box>
            </Box>
          </>
        )}
      </LevelProgressContainer>
      <Box marginTop={1.5}>
        <ProgressBar from={0} to={nextLevelPoints} value={basePoints} currentLevel={currentLevel} />
      </Box>
    </CurrentLevelContainer>
  );
};

export default CurrentLevel;
