import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled, Drawer, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { NAV_LINKS } from 'pages/routes';
import { openLink } from 'utils/openLink';
import { SOCIAL_MEDIAS } from 'constants';

/********************  Styled Components  ********************/
const ContentWrapper = styled('div')(({ theme }) => ({
  width: 450,
  minHeight: '-webkit-fill-available',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.filter,
  marginTop: '72px',

  '@media (max-width: 600px)': {
    width: '100vw',
  },
}));

const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const FilterMenuItem = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  height: 60,
  color: theme.palette.text.main,
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.border.separator}`,

  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
    opacity: 0.8,
  },
}));

const SocialMediaContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 62.3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  gap: 30,

  a: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MenuItemArrowIcon = styled('div')(({ theme }) => ({
  flex: 1,
  justifyContent: 'flex-end',
  display: 'flex',
  color: theme.palette.icon.tertiary,
}));

/********************  Main Component  ********************/
const SideNavigation = ({ open, closeSideBar }) => {
  const theme = useTheme();
  const [sideNav, setSideNav] = useStateIfMounted(null);

  return (
    <Drawer anchor="right" open={open}>
      <ContentWrapper>
        <Container>
          {!sideNav ? (
            NAV_LINKS.map((menu, index) => (
              <FilterMenuItem
                key={index}
                to={menu.path}
                onClick={() => (menu.submenus ? setSideNav(menu.name) : closeSideBar())}
              >
                <ListItemText primaryTypographyProps={{ fontWeight: '600' }} primary={menu.name} />
                {menu.submenus && <ChevronRight />}
              </FilterMenuItem>
            ))
          ) : (
            <>
              <FilterMenuItem onClick={() => setSideNav(false)}>
                <MenuItemArrowIcon style={{ flex: 'initial' }}>
                  <ChevronLeft style={{ color: theme.palette.text.main }} />
                </MenuItemArrowIcon>
                <ListItemText primaryTypographyProps={{ fontWeight: '600' }} primary={sideNav} />
              </FilterMenuItem>
              {NAV_LINKS.find(({ name }) => name === 'Resources')?.submenus.map(
                ({ name, path }) => (
                  <FilterMenuItem key={name}>
                    <ListItemText
                      primaryTypographyProps={{ fontWeight: '600' }}
                      primary={name}
                      onClick={() => {
                        openLink(path);
                        setSideNav(null);
                        closeSideBar();
                      }}
                    />
                  </FilterMenuItem>
                ),
              )}
            </>
          )}
        </Container>

        <SocialMediaContainer>
          {SOCIAL_MEDIAS.map((media, index) => (
            <a
              key={index}
              href={media.link}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#8A939B' }}
            >
              {media.icon}
            </a>
          ))}
        </SocialMediaContainer>
      </ContentWrapper>
    </Drawer>
  );
};

export default SideNavigation;
