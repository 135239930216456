import React from 'react';
import { styled } from '@mui/material';
import { Text } from 'components/Text';
import { GMITokenLogo } from 'components/ImageComponent';

/********************  Styled Components  ********************/
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: 'max-content',
  height: 48,
  borderRadius: 8,
  border: `1px solid ${theme.palette.border.decorative}`,
  backgroundColor: theme.palette.background.tertiary,
  translate: '24px',
  padding: '0 40px 0 16px',
  gap: 10,

  '@media (max-width: 550px)': {
    display: 'none',
  },
}));

/********************  Main Component  ********************/
const GmiAmount = ({ amount }) => {
  return (
    <Container>
      <GMITokenLogo />
      <Text main="true" fs={16} fw={600} lh={24}>
        {amount} GMI
      </Text>
    </Container>
  );
};

export default GmiAmount;
