import { styled, Backdrop, Tooltip, tooltipClasses, Button, alpha } from '@mui/material';
import MoneyFace from 'assets/emojis/money-face.png';
import Diamond from 'assets/emojis/diamond.png';
import DiamondContainer from 'assets/diamond-emoji-fill.svg';

export const FlexBox = styled('div')(
  ({ width, height, direction, align, justify, padding, margin, gap, mt, mb, my }) => ({
    display: 'flex',
    width: width ?? '100%',
    height: height ?? null,
    flexDirection: direction ?? 'row',
    alignItems: align ?? 'center',
    justifyContent: justify ?? 'flex-start',
    padding: padding ?? 0,
    margin: margin ?? 0,
    gap: gap ?? 0,
    marginTop: mt ?? my ?? 0,
    marginBottom: mb ?? my ?? 0,
  }),
);

export const FlexCol = styled('div')(({ align, justify, gap, width, height }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: align ?? 'center',
  justifyContent: justify ?? 'flex-start',
  gap: gap ?? 0,
  width: width,
  height: height,
}));

export const FlexCenter = styled('div')(({ align, justify, gap, width, height }) => ({
  display: 'flex',
  alignItems: align ?? 'center',
  justifyContent: justify ?? 'center',
  gap: gap ?? 0,
  width,
  height,
}));

export const Divider = styled('div')(({ theme, bg }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: bg ?? theme.palette.border.decorative,
}));

export const ToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.border.high,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.border.high,
    fontSize: '14px',
    fontWeight: 500,
    padding: '6px 8px',
    letterSpacing: '0.25px',
    marginTop: '0px !important',
    marginBottom: '10px !important',
    marginRight: '0px !important',
    textAlign: 'center',
    lineHeight: '20px',
  },
}));

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  background: theme.palette.background.overlay,
  zIndex: 900,
}));

export const LevelProgressDot = styled('div')(
  ({ theme, clicked, yellow, white, green, money }) => ({
    width: 20,
    height: 20,
    borderRadius: 100,

    background: yellow
      ? theme.palette.support.warning
      : green
      ? theme.palette.button.primary
      : white
      ? theme.palette.text.white
      : money
      ? `url(${MoneyFace})`
      : 'rgba(217, 217, 217, 0.2)',

    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',

    '@media (max-width:600px)': {
      position: clicked && 'relative',
    },
  }),
);

export const LevelProgressDiamond = styled('div')(({ clicked, diamondContainer }) => ({
  width: 18,
  height: 18,
  borderRadius: 100,
  background: diamondContainer ? `url(${DiamondContainer})` : `url(${Diamond})`,
  backgroundSize: '100% auto',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',

  '@media (max-width:600px)': {
    position: clicked && 'relative',
  },
}));

export const GroupButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.background.default : theme.palette.background.tertiary,
  color: 'white',
  padding: theme.spacing(1, 2),
  minWidth: 'auto',
  fontSize: 14,
  fontWeight: 500,
  borderRadius: theme.spacing(1),
  color: active ? theme.palette.text.main : theme.palette.text.tertiary,
  border: active ? `1px solid ${theme.palette.active}` : 'none',

  '@media (max-width: 600px)': {
    padding: theme.spacing(1),
  },
  '&:hover': {
    color: theme.palette.text.main,
    background: theme.palette.background.hoverChrome,
  },
}));

export const ActivatedButton = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  border: `1px solid ${theme.palette.primary.main}`,
  borderStyle: 'dashed',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 2),
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '24px',
}));
