import { UserApi } from 'clientSDK';

const userApi = new UserApi();

export default {
  getNonce: async (address) => await userApi.getNonce(address),

  authenticate: async (address, signature, mainWallet) =>
    await userApi.authenticate({ address, signature, mainWallet }),

  getTwitterUser: async () => await userApi.getTwitterUser(),

  getAirdropData: async () => await userApi.getAirdropData(),

  logout: async () => await userApi.logout(),

  checkZealyMembership: async (zealyId) => await userApi.checkZealyMembership({ zealyId }),

  getHodlerBalance: async () => await userApi.getHodlerBalance(),

  getNftVolume: async () => await userApi.getNftVolume(),

  checkEarlyDegen: async () => await userApi.checkEarlyDegen(),

  claimReferralProgramWeekly: async (week) => await userApi.claimReferralProgramWeekly(week),

  checkReferralProgramWeekly: async () => await userApi.checkReferralProgramWeekly(),
};
