import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { getEnsName, getEnsAvatar } from '@wagmi/core';

import { config } from 'constants/walletConfig';

export const useDomains = (address) => {
  const [ENSname, setENSname] = useStateIfMounted(null);
  const [ENSavatar, setENSavatar] = useStateIfMounted(null);

  useEffect(() => {
    getName(address);
  }, [address]);

  useEffect(() => {
    ENSname && getAvatar(ENSname);
  }, [ENSname]);

  const getName = async (address) => {
    if (address) {
      const name = await getEnsName(config, { address });
      setENSname(name);
    }
  };

  const getAvatar = async (ENSname) => {
    const avatar = await getEnsAvatar(config, { name: ENSname });
    setENSavatar(avatar);
  };

  return { ENSname, ENSavatar };
};
