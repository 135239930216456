import { createContext, useEffect, useReducer } from 'react';

import user from 'services/user';

import { useWallet } from './WalletContext';

const initialState = {
  data: [],
  loading: false,
};

const ACTIONS = {
  SET_DATA: 'SET_DATA',
  SET_LOADING: 'SET_LOADING',
};

const HodlerAirdropReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
  }

  throw Error('Unknown action: ' + action.type);
};

export const HodlerAirdropContext = createContext(null);

export const HodlerAirdropProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HodlerAirdropReducer, initialState);
  const { address, isConnected, isAuthenticated } = useWallet();

  const fetchHodlerData = async () => {
    setLoading(true);
    try {
      const data = await user.getHodlerBalance();
      setData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setLoading = (loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: loading });
  };

  const setData = (data) => {
    dispatch({ type: ACTIONS.SET_DATA, payload: data });
  };

  useEffect(() => {
    if (address && isConnected && isAuthenticated) {
      fetchHodlerData();
    }
  }, [address, isConnected, isAuthenticated]);

  return (
    <HodlerAirdropContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </HodlerAirdropContext.Provider>
  );
};
